import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import styles from '../../VehicleMaintenance/VehicleMaintenanceSchedules.module.scss';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';

export const InspectionSummaryToolbar = ({
  isFetching,
  filteredInspectionsCount,
  searchText,
  fleetsFilter,
  driversFilter,
  vehiclesFilter,
  daysFilter,
  statusFilter,
  onSearchChange,
  onFleetsFilterChange,
  onVehiclesFilterChange,
  onDriversFilterChange,
  onDaysFilterChange,
  onStatusFilterChange
}) => {
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.filterWrapper}>
      <FilterWrapper>
        <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
        <AntMultiselect
          title={
            vehiclesFilter?.some(value => !value.checked)
              ? t('Common.Vehicles')
              : t('Common.AllVehicles')
          }
          data={vehiclesFilter}
          onFilter={onVehiclesFilterChange}
        />
        <AntMultiselect
          title={
            fleetsFilter?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
          }
          data={fleetsFilter}
          onFilter={onFleetsFilterChange}
        />
        <AntMultiselect
          title={
            driversFilter?.some(value => !value.checked)
              ? t('Common.Drivers')
              : t('Common.AllDrivers')
          }
          data={driversFilter}
          onFilter={onDriversFilterChange}
        />
        <AntMultiselect
          title={daysFilter?.some(value => !value.checked) ? t('Common.Days') : t('Common.AllDays')}
          data={daysFilter}
          onFilter={onDaysFilterChange}
        />
        <AntMultiselect
          title={t('InspectionSummary.Status')}
          data={statusFilter}
          onFilter={onStatusFilterChange}
        />
      </FilterWrapper>
      <div>
        <div className={styles.documentsCount}>
          {(!isFetching ? filteredInspectionsCount : 0) + ' ' + t('InspectionSummary.Inspections')}
        </div>
      </div>
    </div>
  );
};
