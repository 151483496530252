import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Switch,
  Form,
  Table,
  Spin,
  InputNumber,
  Alert,
  Modal,
  Space,
  Tooltip,
  Grid,
  Row,
  Col,
  Checkbox,
  Typography
} from 'antd';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';

import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';

import {
  useCanEveryCompanyService,
  services,
  FeatureFlag,
  useCanFeatureFlag
} from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import {
  fetchFleets,
  useDevices,
  useIsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';

import {
  useGetSpeedAssistConfigurationTemplatesQuery,
  useAddSpeedAssistConfigurationTemplateMutation,
  useUpdateSpeedAssistConfigurationTemplateMutation,
  useCurrentCompanyServicesMap
} from 'services/nextgen/ngSpeedAssistConfigurationApi';

import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import { PATHS } from './constants';
import { inputValidator } from './helper';
import { useLocalization } from 'features/localization/localizationSlice';
import { UNITS } from 'features/localization/localization';
import { canHistoryGoBack } from 'utils/methods';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { templateTypeEnum } from '../components/constants';
import { HeaderFormSection } from '../components/HeaderFormSection';
import style from '../GPIO/GPIOForm.module.scss';

export const SpeedAssistForm = ({ templateId, action }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [companyLevel, setCompanyLevel] = useState(false);
  const [templateModalData, setTemplateModalData] = useState(undefined);
  const [previousCompanyLevel, setPreviousCompanyLevel] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysForFilteredDevices, setSelectedRowKeysForFilteredDevices] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();
  const [modal, contextHolder] = Modal.useModal();
  const [formValid, setFormValid] = useState(true);

  const types = useDeviceTypesList();
  const iFace = types.find(type => type.code.toLowerCase() === 'iface');
  const external = types.find(type => type.code.toLowerCase() === 'external');
  const allDevices = useDevices();

  const isFetchingFleets = useIsFetching();
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();

  const { data: templateData, isFetching } = useGetSpeedAssistConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );
  const [
    addSpeedAssistConfigurationTemplate,
    { isLoading: isAddingTemplating }
  ] = useAddSpeedAssistConfigurationTemplateMutation();
  const [
    updateSpeedAssistConfigurationTemplate,
    { isLoading: isUpdatingTemplating }
  ] = useUpdateSpeedAssistConfigurationTemplateMutation();

  const templates = useMemo(() => {
    const configurationTemplates = (templateData || []).map(i => {
      const template = i.configurationTemplate;
      return { ...template, associations: { devices: i.associatedDevices } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, templateData, isFetching]);

  const deviceTemplateMap = useMemo(() => {
    const map = {};
    templates.forEach(template => {
      template.associations.devices.forEach(device => {
        map[device.id] = template;
      });
    });
    return map;
  }, [templates]);

  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});
  const companyHermesDevices = useMemo(
    () =>
      allDevices
        .filter(
          device =>
            (device?.type?.id === iFace.id || device?.type?.id === external.id) &&
            device?.company?.id === currentCompany.id &&
            device?.services?.includes('SPEEDASSIST')
        )
        .map(device => {
          const template = deviceTemplateMap[device.id];
          const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
          return { ...device, vehicle: vehicle, template: template };
        }),
    [iFace, external, currentCompany, allDevices, deviceTemplateMap]
  );
  const [devices, setDevices] = useState(companyHermesDevices);

  useEffect(() => {
    setDevices(companyHermesDevices);
  }, [companyHermesDevices]);

  const handleViewTemplateInfo = record => {
    const configuration = record?.template?.configurations;
    setTemplateModalData(configuration);
  };

  const handleSaveForm = async () => {
    let confirmed = true;
    if (previousCompanyLevel === true && companyLevel !== previousCompanyLevel) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.RemovingCompanyDefaultMessage', {
          templateName: templateTypeEnum.SpeedAssist
        })
      });
    }

    // Count of devices which currently assigned to other template
    const count = selectedRowKeys
      .map(id => deviceTemplateMap[id])
      .filter(t => t && t.id !== templateId).length;
    if (confirmed && count > 0) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.DeviceAlreadyAssigned', {
          templateName: templateTypeEnum.SpeedAssist
        })
      });
    }

    const templateServices = new Set();

    if (!companyLevel) {
      const serviceArray = [...templateServices];
      const deviceNotFullfiled = [...new Set(selectedRowKeys)]
        .map(id => devices.find(d => d.id === id))
        .filter(d => !serviceArray.every(e => d?.services?.includes(e))).length;
      if (confirmed && deviceNotFullfiled > 0) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceNotFulfilled', {
            count: deviceNotFullfiled
          })
        });
      }
    }

    if (confirmed) {
      submitForm();
    }
  };

  const submitForm = () => {
    const serviceMapInfo = serviceMap['SPEEDASSIST'];
    let configs = [];

    form.validateFields().then(values => {
      let config = form.getFieldValue('signposted.disable');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'signposted.disable',
          value: config
        });
      }
      config = form.getFieldValue('company.managed');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'company.managed',
          value: config
        });
      }
      config = form.getFieldValue('company.max.threshold');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'company.max.threshold',
          value: localization.convertSpeedWithUnit(
            Number(config),
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        });
      }
      config = form.getFieldValue('company.max.offset');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'company.max.offset',
          value: localization.convertSpeedWithUnit(
            Number(config),
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        });
      }
      config = form.getFieldValue('company.max.duration');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'company.max.duration',
          value: config
        });
      }

      const configurationTemplateRequest = {
        configurationTemplate: {
          name: values.name,
          description: values.description,
          type: 'SPEED_ASSIST',
          company: {
            id: currentCompany.id
          },
          status: 'ENABLED',
          default: values.default,
          configurations: configs
        }
      };

      configurationTemplateRequest.deviceIds = [...new Set(selectedRowKeys)];

      if (action === 'add') {
        addSpeedAssistConfigurationTemplate({ body: configurationTemplateRequest })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      } else {
        updateSpeedAssistConfigurationTemplate({
          body: configurationTemplateRequest,
          id: templateId
        })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      }
    });
  };

  const handleResult = result => {
    const response = result.data;
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(result?.error?.data)}`
        })
      );
      return;
    }
    if (response?.errors?.length > 0) {
      const mainMessage = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarning', {
        count: response.associatedDevices?.length
      });
      const errorMessages = response.errors.map(err => (
        <React.Fragment>
          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarningDetail', {
            count: err.devices?.length,
            error: t(
              'CompanyConfig.DeviceConfigurations.GPIOTemplates.error.' + err.message,
              err.message
            )
          })}
          <br />
        </React.Fragment>
      ));
      const finalMessage = (
        <React.Fragment>
          {mainMessage}
          <br />
          {errorMessages}
        </React.Fragment>
      );
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: finalMessage
        })
      );

      dispatch(fetchFleets());
      canHistoryGoBack(history, PATHS.SPEEDASSIST_DEFAULT);
      return;
    }

    dispatch(fetchFleets());
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
      })
    );
    canHistoryGoBack(history, PATHS.SPEEDASSIST_DEFAULT);
  };

  useEffect(() => {
    if (templateId) {
      const editingTemplate = { ...templates.find(item => item.id === templateId) };
      form.setFieldsValue(editingTemplate);
      let config = editingTemplate?.configurations?.find(item => item.key === 'signposted.disable');
      if (config) {
        form.setFieldValue('signposted.disable', config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'company.managed');
      if (config) {
        form.setFieldValue('company.managed', config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'company.max.threshold');
      if (config) {
        form.setFieldValue('company.max.threshold', localization.convertSpeed(config.value, 0));
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'company.max.offset');
      if (config) {
        form.setFieldValue('company.max.offset', localization.convertSpeed(config.value, 0));
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'company.max.duration');
      if (config) {
        form.setFieldValue('company.max.duration', config.value);
      }

      setCompanyLevel(editingTemplate.default);
      setPreviousCompanyLevel(editingTemplate.default);
      const selectedDeviceIds = editingTemplate.associations?.devices?.map(d => d.id);
      setSelectedRowKeys(selectedDeviceIds);
      setSelectedRowKeysForFilteredDevices(selectedDeviceIds);
    }
  }, [templates, templateId]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        t(
          action === 'add'
            ? 'CompanyConfig.DeviceConfigurations.AddTemplate'
            : 'CompanyConfig.DeviceConfigurations.UpdateTemplate',
          { templateName: t('CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Title') }
        )
      )
    );
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    const deviceKeys = new Set(devices.map(d => d.id));
    // Keep only the state of the selected devices that are out of the filtered list.
    let newSelectedKeys = selectedRowKeys.filter(k => !deviceKeys.has(k));
    newSelectedKeys = [...newSelectedKeys, ...(selectedRowKeysForFilteredDevices ?? [])];
    setSelectedRowKeys(newSelectedKeys);
  }, [selectedRowKeysForFilteredDevices]);

  const initializing = isFetching || isFetchingFleets || isFetchingVehicles;

  if (initializing) {
    return <Spin size="large"></Spin>;
  }

  const formItemStyle = {
    marginBottom: '3px' // Adjust the bottom margin as needed
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Update the selected rows in the state
      setSelectedRowKeysForFilteredDevices(selectedRowKeys);
      setDirty(true);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          setDirty(true);
          setFormValid(allValues.name && allValues.name.trim() !== '');
        }}
      >
        {/* Panel 1 */}
        <Card className={style.headerSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Basic Information')}
          </Typography.Title>
          <HeaderFormSection
            formItemStyle={formItemStyle}
            setCompanyLevel={setCompanyLevel}
            templateName={'SPEEDASSIST'}
          />
        </Card>

        {/* Panel 2 */}
        <Card className={style.configSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Configurations')}
          </Typography.Title>
          <>
            <Row justify={'start'}>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManagedTooltip'
                  )}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManaged'
                  )}
                  name="company.managed"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={false} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabledTooltip'
                  )}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabled'
                  )}
                  name="signposted.disable"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={false} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThresholdTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThreshold')}
                  rules={inputValidator().number}
                  name="company.max.threshold"
                  initialValue={0}
                >
                  <InputNumber precision={0} placeholder={0} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffsetTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffset')}
                  rules={inputValidator().number}
                  name="company.max.offset"
                  initialValue={0}
                >
                  <InputNumber precision={0} placeholder={0} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDurationTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDuration')}
                  rules={inputValidator().number}
                  name="company.max.duration"
                  initialValue={0}
                >
                  <InputNumber precision={0} placeholder={0} />
                </Form.Item>
              </Col>
            </Row>
          </>
        </Card>
      </Form>

      {/* Panel 3 */}
      {companyLevel && (
        <Card className={style.deviceSectionCardCompanyLevel}>
          <Alert
            message={t('CompanyConfig.DeviceConfigurations.CompanyLevelNote', {
              templateName: templateTypeEnum.SpeedAssist
            })}
            type="info"
            showIcon
          />
        </Card>
      )}
      {!companyLevel && (
        <DevicesAssignmentTable
          filteredDevices={companyHermesDevices}
          devices={devices}
          setDevices={setDevices}
          templates={templates}
          currentCompany={currentCompany}
          handleViewTemplate={handleViewTemplateInfo}
          rowSelection={rowSelection}
          templateType={templateTypeEnum.SpeedAssist}
          useTitle={true}
        />
      )}

      {/* Template Modal */}
      {templateModalData && (
        <Modal
          title={t('Common.Configuration')}
          onCancel={() => {
            setTemplateModalData(undefined);
          }}
          footer={null}
          open={true}
          width={1000}
          centered
        >
          <Form layout="vertical">
            <Row justify={'start'}>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.CompanyManaged'
                  )}
                >
                  <Switch
                    disabled={true}
                    checked={
                      templateModalData?.find(item => item.key === 'company.managed')?.value ===
                      'true'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t(
                    'CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.SignpostedDisabled'
                  )}
                >
                  <Switch
                    disabled={true}
                    checked={
                      templateModalData?.find(item => item.key === 'signposted.disable')?.value ===
                      'true'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxThreshold')}
                  rules={inputValidator().number}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={localization.convertSpeed(
                      templateModalData?.find(item => item.key === 'company.max.threshold')
                        ?.value || 0,
                      0
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxOffset')}
                  rules={inputValidator().number}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={localization.convertSpeed(
                      templateModalData?.find(item => item.key === 'company.max.offset')?.value ||
                        0,
                      0
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t('CompanyConfig.DeviceConfigurations.SPEEDASSISTTemplates.MaxDuration')}
                  rules={inputValidator().number}
                >
                  <InputNumber
                    disabled={true}
                    precision={0}
                    value={
                      templateModalData?.find(item => item.key === 'company.max.duration')?.value ||
                      0
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}

      {/* Footer */}
      <Card className={style.footerSectionCard}>
        <Space>
          <Button
            type="primary"
            size="large"
            disabled={!dirty || !formValid || isAddingTemplating || isUpdatingTemplating}
            onClick={handleSaveForm}
            id={BUTTON_IDS.speedAssistFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.speedAssistFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </Card>
      <EditRouteGuard
        when={dirty && templateModalData === undefined}
        navigate={history.push}
      ></EditRouteGuard>
    </>
  );
};
