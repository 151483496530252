import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { Button, Card, Image, Space, Modal, Progress, Row, Col, Spin } from 'antd';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import styles from 'components/tn/grid/grid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Empty } from 'components/ant';
import ImageCard from './ImageCard';
import {
  fetchInstallationViewPhotosByEventId,
  useInstallationViewPhotosByEventId
} from 'features/installation_logs/InstallationLogsSlice';
import i18n from 'i18next';
import useDownloadImages from './useDownloadImages';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import ProgressModal from './ProgressModal';
import { ImagePdfGenerator } from './PdfGenerator';
import { useLocalization } from 'features/localization/localizationSlice';
import { SupportTypesEnum } from './InstallationLogs';
import { useParams } from 'react-router';
import { LoadingTable } from 'components/grid/LoadingTable';

//@Author Durga Prasad Kolli
const InstallationAuditLogsPhotos = () => {
  const localization = useLocalization();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [extension, setExtension] = useState('');
  const { supportType, id } = useParams();
  const baseTitle =
    supportType === SupportTypesEnum.ENGINEER
      ? viewData?.[SupportTypesEnum.USER]?.name
      : viewData?.[supportType]?.name || '';
  const title = baseTitle ? `${baseTitle} - ${t('Common.Photos')}` : t('Common.Photos');
  const { downloadImagesAsZip } = useDownloadImages();
  const { loading, data } = useInstallationViewPhotosByEventId();

  useEffect(() => {
    dispatch(fetchInstallationViewPhotosByEventId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data && data.length > 0) {
      const resp = data[0];
      setViewData(resp);
      setAttachments(resp?.attachments ?? []);
      dispatch(setBackButton(true));
      dispatch(setPageTitle(t(title)));
    }
  }, [dispatch, data, title, t]);

  const [progress, setProgress] = useState({ total: 0, completed: 0 });
  const [modalVisible, setModalVisible] = useState(false);

  const handleProgress = ({ total, completed }) => {
    setProgress({ total, completed });
  };

  const handleZIPDownloadClick = async () => {
    setExtension('.zip');
    setProgress({ total: 0, completed: 0 });
    setModalVisible(true);
    if (!attachments || !Array.isArray(attachments)) {
      console.warn('Attachments must be an array.');
      return;
    }
    try {
      await downloadImagesAsZip(attachments, handleProgress, title);
      setTimeout(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Common.DownloadedSuccessfully', {
              title: i18n.t(title) + '.zip'
            })
          })
        );
      }, 5000);
    } catch (error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error?.message
        })
      );
      console.error('Error fetching images:', error);
    } finally {
      setModalVisible(false);
    }
  };
  const handlePDFDownloadClick = async () => {
    setExtension('.pdf');
    setProgress({ total: 0, completed: 0 });
    setModalVisible(true);
    if (!attachments || !Array.isArray(attachments)) {
      console.warn('Attachments must be an array.');
      return;
    }
    try {
      await ImagePdfGenerator(attachments, localization, handleProgress, title);
      setTimeout(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Common.DownloadedSuccessfully', {
              title: i18n.t(title) + '.pdf'
            })
          })
        );
      }, 5000);
    } catch (error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error?.message
        })
      );
      console.error('Error fetching images:', error);
    } finally {
      setModalVisible(false);
    }
  };

  if (loading) return <LoadingTable nrOfRows={1} columnSizes={[100, 100, 100, 100, 100, 100]} />;

  return (
    <ContainerPageWrapper>
      <HeaderPageWrapper>
        <div style={{ display: 'flex' }}></div>
        <div style={{ marginLeft: 'auto' }}>
          <Space size={16}>
            <Button
              type="primary"
              size="medium"
              id={BUTTON_IDS.viewPhotosBtn}
              onClick={handlePDFDownloadClick}
              disabled={attachments.length === 0}
            >
              {t('SupportToolsELD.ExportPhotostoPDF')}
            </Button>
            <Button
              type="secondary"
              size="medium"
              id={BUTTON_IDS.exportToPDFBtn}
              onClick={handleZIPDownloadClick}
              disabled={attachments.length === 0}
            >
              {t('SupportToolsELD.ExportAsZIP')}
            </Button>
          </Space>
        </div>
      </HeaderPageWrapper>
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '0 32px', color: '#181c21' }}
      >
        {viewData && (
          <>
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)}
              value={viewData?.vehicle?.name}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Company`)}
              value={viewData.company?.name || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
              value={
                viewData?.fleets && viewData.fleets.length > 0
                  ? viewData.fleets.map(f => f.name)?.join(', ')
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
              value={viewData?.user?.name || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
              value={viewData.vehicle?.workorder || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
              value={viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''}
              styles={styles}
            />
            <div>
              <ProgressModal
                title={`${title}${extension}`}
                open={modalVisible}
                progress={progress}
                onClose={() => setModalVisible(false)}
                t={t}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              {attachments.length === 0 ? <Empty /> : <ImageCard attachments={attachments} t={t} />}
            </div>
          </>
        )}
      </div>
    </ContainerPageWrapper>
  );
};
export default InstallationAuditLogsPhotos;
