import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { NoAccessBody } from 'components/pages/NoAccess';
import {
  Can,
  entities,
  useCanOneOfRoles,
  useCanOneOfEntities,
  GlobalRoles,
  services,
  FeatureFlag
} from 'features/permissions';
import { CompanyConfig } from '../CompanyConfig';
import { BulkManagement } from 'containers/Administration/BulkManagement/BulkManagement';
import { ELDCarrier } from 'containers/Configuration/CompanyConfig/Forms/CarrierList';
import { IQCamera } from 'containers/Configuration/CompanyConfig/Forms/IQCamera/IQCamera';
import { IQCameraConfigAudit } from '../Forms/IQCamera/IQCameraConfigAudit';
import { IQCameraAssociationAudit } from '../Forms/IQCamera/IQCameraAssociationAudit';
import { PATHS } from '../utils/constants';
import { SharedLinks } from 'containers/Administration/SharedLinks/SharedLinks';
import { CompanyAgreementRouter } from 'containers/Configuration/CompanyConfig/Forms/CompanyAgreement/CompanyAgreementRouter';
import { GPIORouter } from '../Forms/GPIO/GPIORouter';
import { SPEEDASSISTRouter } from '../Forms/SPEEDASSIST/SPEEDASSISTRouter';
import { DeviceConfig } from '../DeviceConfig';
import { LoadingOrNoAccess } from '../Forms/components/LoadingOrNoAccess';
import { DriverIdRouter } from '../Forms/DriverId/DriverIdRouter';

const CompanyConfigRouter = () => {
  // This (canCompanyConfig) is duplicate of Settings.js page, looking into a way to have it one place
  const canAdminRole = useCanOneOfRoles([
    GlobalRoles.Admin,
    GlobalRoles.IQCameraAdministrator,
    GlobalRoles.SubCompanyAdmin
  ]);
  const canResellerRole = useCanOneOfRoles([GlobalRoles.Admin, GlobalRoles.Reseller]);
  const canBulkImport = useCanOneOfEntities([entities.BULKIMPORT]);
  const canCompanyConfig = canAdminRole || canBulkImport || canResellerRole;

  return (
    <Switch>
      <Route path={PATHS.BULK_MANAGEMENT}>
        <Can
          everyEntity={[entities.BULKIMPORT]}
          oneOfEntities={[entities.USER, entities.VEHICLE, entities.GEOFENCE, entities.LOCATION]}
          onFail={<NoAccessBody />}
        >
          <BulkManagement />
        </Can>
      </Route>
      <Route path={PATHS.ELD_CARRIER}>
        <Can
          //featureFlag={FeatureFlag.bulkImport.flag}
          //everyEntity={[entities.BULKIMPORT]}
          oneOfEntities={[entities.USER, entities.VEHICLE, entities.GEOFENCE, entities.LOCATION]}
          onFail={<NoAccessBody />}
        >
          <ELDCarrier />
        </Can>
      </Route>
      <Route path={PATHS.IQ_CAMERA_CONFIG_AUDIT}>
        <Can
          oneOfRoles={[GlobalRoles.Admin, GlobalRoles.IQCameraAdministrator]}
          everyCompanyService={[services.IQ]}
          onFail={<Redirect to={PATHS.IQ_CAMERA} />}
        >
          <IQCameraConfigAudit />
        </Can>
      </Route>
      <Route path={PATHS.IQ_CAMERA_ASSOCIATION_AUDIT}>
        <Can
          oneOfRoles={[GlobalRoles.Admin, GlobalRoles.IQCameraAdministrator]}
          everyCompanyService={[services.IQ]}
          onFail={<Redirect to={PATHS.IQ_CAMERA} />}
        >
          <IQCameraAssociationAudit />
        </Can>
      </Route>
      <Route path={PATHS.IQ_CAMERA}>
        <Can
          oneOfRoles={[GlobalRoles.Admin, GlobalRoles.IQCameraAdministrator]}
          everyCompanyService={[services.IQ]}
          onFail={<LoadingOrNoAccess suspendedTitle={'CompanyConfig.IQCamera.Title'} />}
        >
          <IQCamera />
        </Can>
      </Route>
      <Route path={PATHS.SHARED_LINKS}>
        <SharedLinks />
      </Route>
      <Route path={PATHS.AGREEMENT_LINKS}>
        <Can
          oneOfRoles={[GlobalRoles.SiteAdmin, GlobalRoles.Reseller, GlobalRoles.SubCompanyAdmin]}
          onFail={<NoAccessBody />}
        >
          <CompanyAgreementRouter />
        </Can>
      </Route>
      <Route path={PATHS.GPIO_LINKS}>
        <Can
          oneOfRoles={[GlobalRoles.SiteAdmin, GlobalRoles.Reseller]}
          oneOfCompanyServices={[services.GPIO]}
          onFail={<NoAccessBody />}
        >
          <GPIORouter />
        </Can>
      </Route>
      <Route path={PATHS.SPEEDASSIST_LINKS}>
        <Can
          featureFlag={FeatureFlag.deviceConfig_speedAssist.flag}
          oneOfRoles={[GlobalRoles.SiteAdmin, GlobalRoles.Reseller, GlobalRoles.Admin]}
          oneOfCompanyServices={[services.SPEEDASSIST]}
          onFail={<NoAccessBody />}
        >
          <SPEEDASSISTRouter />
        </Can>
      </Route>
      <Route path={PATHS.DRIVER_ID}>
        <Can
          featureFlag={FeatureFlag.driverIdPage.flag}
          oneOfRoles={[GlobalRoles.SiteAdmin, GlobalRoles.Reseller]}
          oneOfCompanyServices={[services.DRIVERPIN]}
          onFail={<NoAccessBody />}
        >
          <DriverIdRouter />
        </Can>
      </Route>
      <Route path={PATHS.DEVICE_CONFIG_LINKS}>
        <Can
          oneOfRoles={[GlobalRoles.SiteAdmin, GlobalRoles.Reseller, GlobalRoles.Admin]}
          onFail={<NoAccessBody />}
        >
          <DeviceConfig />
        </Can>
      </Route>
      {/* <Route path={PATHS.LOG_ON_EVENTS}>
        <Can featureFlag={FeatureFlag.logOnEventsConfiguration.flag} onFail={<NoAccessBody />}>
          <div />
        </Can>
      </Route> */}
      <Route path={PATHS.COMPANY_CONFIG}>
        <Can otherConditions={[() => canCompanyConfig]} onFail={<NoAccessBody />}>
          <CompanyConfig />
        </Can>
      </Route>
    </Switch>
  );
};

export default CompanyConfigRouter;
