import React from 'react';
import { Table } from 'antd';
import i18n from 'i18next';

import styles from './AgreementTable.module.scss';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { FeatureFlag, useCan } from 'features/permissions';
import { Link } from 'react-router-dom';

export const ColumnKeys = {
  DESCRIPTION: 'description',
  WORK_ORDER: 'workOrder',
  WORK_ORDER_RAW: 'workOrderRaw',
  SUBSCRIPTION: 'subscription',
  STATUS: 'status',
  DETAIL: 'detail',
  END_AT: 'contractEndAt',
  ACTIONS: 'actions'
};

const columns = () => {
  return [
    {
      title: i18n.t('CompanyConfig.CompanyAgreement.WorkOrder'),
      dataIndex: ColumnKeys.WORK_ORDER,
      width: 150,
      key: ColumnKeys.WORK_ORDER,
      className: 'topAlign',
      sorter: (a, b) => (a.workOrderRaw || '').localeCompare(b.workOrderRaw || '')
    },
    {
      title: i18n.t('CompanyConfig.CompanyAgreement.AgreementDescription'),
      dataIndex: ColumnKeys.DESCRIPTION,
      width: 200,
      key: ColumnKeys.DESCRIPTION,
      className: 'topAlign',
      sorter: (a, b) => (a.description || '').localeCompare(b.description || '')
    },
    {
      title: i18n.t('CompanyConfig.CompanyAgreement.SubscriptionPack'),
      dataIndex: ColumnKeys.SUBSCRIPTION,
      key: ColumnKeys.SUBSCRIPTION,
      className: 'topAlign',
      sorter: (a, b) => (a.subscription || []).length - (b.subscription || []).length
    },
    {
      title: i18n.t('CompanyConfig.CompanyAgreement.AgreementDetail'),
      dataIndex: ColumnKeys.DETAIL,
      key: ColumnKeys.DETAIL,
      className: 'topAlign',
      sorter: (a, b) => moment(a.contractEndAt).diff(moment(b.contractEndAt))
    },
    {
      title: i18n.t('CompanyConfig.CompanyAgreement.Actions'),
      dataIndex: ColumnKeys.ACTIONS,
      key: ColumnKeys.ACTIONS,
      className: 'topAlign',
      width: 100
    }
  ];
};

export const PrepareDataForTable = ({ agreementData, handleAction }) => {
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const subCompanies = useSubCompanies();
  const can = useCan();

  return (agreementData || []).map((entry, index) => {
    const dateDetail = (
      <div key={index}>
        <div>{i18n.t('CompanyConfig.CompanyAgreement.Contract')}</div>
        <div>{`${moment(entry.contractStartAt).format(
          localization.formats.time.formats.dby_imp
        )} - ${moment(entry.contractEndAt).format(
          localization.formats.time.formats.dby_imp
        )}`}</div>
        <div>{i18n.t('CompanyConfig.CompanyAgreement.Technical')}</div>
        <div>{`${moment(entry.enabledAt).format(
          localization.formats.time.formats.dby_imp
        )} - ${moment(entry.expiresAt).format(localization.formats.time.formats.dby_imp)}`}</div>
      </div>
    );

    const menuItems = [
      {
        label: `${i18n.t('Common.EllipsisButton.View')}`,
        link: `/settings/company_config/agreement/id/${entry.agreements[0].id}`,
        visible: true,
        id: 'btn_agreementView'
      },
      can({ featureFlag: FeatureFlag.subCompanyManagement.flag }) &&
        currentCompany.parent?.id === undefined &&
        subCompanies.filter(i => i.id !== currentCompany?.id).length !== 0 && {
          label: `${i18n.t('Agreement.CopyToSubCompany')}`,
          visible: true,
          id: 'btn_agreementCopy',
          onClick: () => handleAction({ data: entry, action: 'copyAgreement' })
        }
    ];

    return {
      [ColumnKeys.WORK_ORDER]: (
        <Link to={`/settings/company_config/agreement/id/${entry.agreements[0].id}`}>
          {entry.workOrder}
        </Link>
      ),
      [ColumnKeys.DESCRIPTION]: entry.description,
      [ColumnKeys.SUBSCRIPTION]: (entry.agreements || [])
        .filter(i => i.status === 'ENABLED')
        .map((data, i) => <div key={i}>{data.subscriptionPack.name}</div>),
      [ColumnKeys.DETAIL]: dateDetail,
      [ColumnKeys.END_AT]: entry.contractEndAt,
      [ColumnKeys.WORK_ORDER_RAW]: entry.workOrder,
      [ColumnKeys.ACTIONS]: (
        <EllipsisMenu
          menuItems={menuItems}
          menuStyle={{ width: '194px', top: '-80px', right: '45px' }}
        />
      ),
      key: index
    };
  });
};

export const AgreementTable = ({ agreementData, loading, handleAction }) => (
  <Table
    className={styles.table}
    columns={columns()}
    dataSource={PrepareDataForTable({
      agreementData,
      handleAction
    })}
    loading={loading}
    pagination={false}
    rowKey="key"
    scroll={{ y: 'calc(100vh - 180px)' }}
    showSorterTooltip={false}
  />
);
