import React, { useState } from 'react';
import { Button, Card, Modal, Row, Col, Tooltip } from 'antd';
import { DownloadIcon, LeftIcon, RightIcon } from './svgs/SvgIcons';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';

const ImageCard = ({ attachments, t }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const localization = useLocalization();
  const handlePreview = index => {
    setCurrentImageIndex(index);
    setPreviewVisible(true);
  };
  const handleCancel = () => setPreviewVisible(false);
  const handleNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % attachments.length);
  };
  const handlePrev = () => {
    setCurrentImageIndex((currentImageIndex + attachments.length - 1) % attachments.length);
  };

  const extractCoordinates = gpsString => {
    let result = {
      latitude: '',
      longitude: ''
    };
    if (!gpsString) {
      return result;
    }
    // Regular expression to match latitude and longitude
    const regex = /Gps\(([^,]*),\s*([^,]*),\s*[^)]*\)/;
    const match = gpsString.match(regex);
    if (match) {
      const latitude = match[1].trim();
      const longitude = match[2].trim();
      result.latitude = latitude !== 'null' && latitude !== '' ? latitude : '';
      result.longitude = longitude !== 'null' && longitude !== '' ? longitude : '';
    }
    return result;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {attachments.map((attachment, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card
              className="card-container"
              cover={
                <div
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handlePreview(index)}
                >
                  <img
                    alt={`Image ${index}`}
                    src={attachment.url}
                    style={{
                      width: '100%',
                      height: '171px',
                      //  maxWidth: '285px',
                      maxHeight: '170px',
                      objectFit: 'contain'
                    }}
                  />
                  <Tooltip title={t('Easydocs.Download')}>
                    {' '}
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: '#2B323C',
                        textAlign: 'center',
                        width: 'Hug (26px)px',
                        height: 'Fixed (20px)px',
                        top: '9px',
                        right: '20px',
                        padding: '1px 8px 1px 8px',
                        gap: '2px',
                        borderRadius: '10px',
                        opacity: '0px'
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        handleDownload(attachment);
                      }}
                    >
                      <DownloadIcon />
                    </div>
                  </Tooltip>
                </div>
              }
            >
              <div className="card-styles">
                <text style={{ display: 'block', fontWeight: 'bold' }}>
                  {attachment?.name?.split('.')[0]}
                </text>
                {/* <text style={{ size: '12px' }}>{moment(attachment.createdAt).format('MM/DD/YYYY hh:mm A')}</text> */}
                <text style={{ size: '12px' }}>
                  {format(attachment.createdAt, localization.formats.time.formats.dby_imp)}
                </text>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="image-modal">
        <Modal
          open={previewVisible}
          footer={null}
          onCancel={handleCancel}
          centered
          style={{ position: 'relative', backgroundColor: 'black' }}
          width="50%"
        >
          <div>
            <h4>{attachments[currentImageIndex]?.name?.split('.')[0]}</h4>
            <div>
              {t('Common.title', { title: 'Caption' })}:{' '}
              <span>{getValue(attachments[currentImageIndex]?.context?.caption)} </span>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div
                class="col-md-6"
                style={{ paddingLeft: '0px', paddingRight: '0px', marginBottom: '5px' }}
              >
                <div>
                  {t('Common.title', { title: 'Timestamp' })}:{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {getValue(attachments[currentImageIndex]?.context?.timeAt) !== '' &&
                      format(
                        getValue(attachments[currentImageIndex]?.context?.timeAt),
                        localization.formats.time.formats.dby_imp
                      )}
                  </span>
                </div>
                <div>
                  {t('Common.title', { title: 'Longitude' })}:{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {extractCoordinates(attachments[currentImageIndex]?.context?.GPS).longitude}
                  </span>
                </div>
                <div>
                  {t('Common.title', { title: 'Latitude' })}:{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {extractCoordinates(attachments[currentImageIndex]?.context?.GPS).latitude}
                  </span>
                </div>
              </div>

              <div
                class="col-md-6"
                style={{ paddingLeft: '70px', paddingRight: '0px', marginBottom: '5px' }}
              >
                <div>
                  {t('Common.title', { title: 'Installer' })}:{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {getValue(attachments[currentImageIndex]?.context?.installerId)}
                  </span>
                </div>
                <div>
                  {t('Common.title', { title: ' Work Order Number' })}:{' '}
                  <span style={{ marginLeft: '5px' }}>
                    {getValue(attachments[currentImageIndex]?.context?.workOrder)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              maxHeight: '480px',
              width: '100%',
              overflow: 'auto',
              objectFit: 'contain',
              backgroundColor: 'black'
            }}
          >
            <img
              src={attachments[currentImageIndex]?.url}
              alt={`Preview ${currentImageIndex}`}
              style={{ maxWidth: '100%', maxHeight: '480px', objectFit: 'contain' }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '5%',
              top: '60%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              zIndex: 1000
            }}
            onClick={handlePrev}
          >
            <LeftIcon />
          </div>

          <div
            style={{
              position: 'absolute',
              right: '5%',
              top: '60%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              zIndex: 1000
            }}
            onClick={handleNext}
          >
            <RightIcon />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', gap: '10px' }}>
            <Button type="primary" onClick={() => handleDownload(attachments[currentImageIndex])}>
              Download
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default ImageCard;

const handleDownload = async attachment => {
  try {
    const response = await fetch(attachment.url, { mode: 'cors', cache: 'no-cache' });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export const getValue = text => {
  if (text && text !== 'null') return text;
  return '';
};
