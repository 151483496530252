import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompanies, useIsFetchingCompany } from 'features/company/companySlice';
import {
  Button,
  Col,
  Form,
  Radio,
  Modal,
  Row,
  Tabs,
  Table,
  Checkbox,
  Space,
  Select,
  Alert
} from 'antd';
import styles from './VehicleTransferModal.module.scss';
import { useGetCompanyAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';

export const VehicleTransferModal = ({
  vehicle,
  devices,
  isOpen,
  onClose,
  onComplete,
  targetCompanyId
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const companies = useCompanies();
  const isFetchingCompany = useIsFetchingCompany();
  const [vehicleTransferForm] = Form.useForm();
  const [assignAgreement, setAssignAgreement] = useState(false);
  const selectedCompany = Form.useWatch('targetCompany', vehicleTransferForm);
  const [showVehicleWarning, setShowVehicleWarning] = useState(false);
  const selectedCompanyName = companies?.find(i => i.id == selectedCompany)?.name;

  const { data: agreementData, isFetching: isFetchingAgreement } = useGetCompanyAgreementsQuery(
    {
      id: selectedCompany
    },
    { skip: selectedCompany === undefined }
  );

  useEffect(() => {
    if (isOpen && vehicle && targetCompanyId) {
      vehicleTransferForm.setFieldsValue({
        currentCompany: vehicle.companyId,
        deviceOnly: false,
        targetCompany: parseInt(targetCompanyId)
      });
    }
  }, [vehicle, targetCompanyId, isOpen]);

  const footer = () => {
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            vehicleTransferForm.submit();
          }}
          htmlType="submit"
        >
          {t('Common.Save')}
        </Button>
        <Button onClick={onClose}>{t('Common.CancelButton')}</Button>
      </>
    );
  };

  const columns = [
    {
      title: t('Vehicles.TransferModal.WorkOrder'),
      dataIndex: 'workOrder',
      key: 'name',
      width: '25%'
    },
    {
      title: t('Vehicles.TransferModal.ContractEndDate'),
      render: (e, r) => {
        return (
          <span>{format(new Date(r.contractEndAt), localization.formats.time.formats.dby)}</span>
        );
      },
      width: '20%'
    },
    {
      title: t('Vehicles.TransferModal.Description'),
      dataIndex: 'description',
      key: 'description',
      width: '30%'
    },
    {
      title: t('Vehicles.TransferModal.SubscriptionPack'),
      dataIndex: 'description',
      key: 'description',
      render: (e, r) => {
        return <span>{r.subscriptionPack.name}</span>;
      },
      width: '25%'
    }
  ];

  const deviceTab = useMemo(() => {
    return devices
      ?.filter(i => i.checked)
      .map(d => {
        return {
          key: d.value,
          label: d.label,
          children: (
            <Row gutter={[64, 0]}>
              <Col xs={24}>
                <Form.Item
                  name={'deviceOnly_' + d.value}
                  labelAlign={'left'}
                  label={t(`Vehicles.TransferModal.TansferOption`)}
                  colon={false}
                  className={styles.settingDiv}
                  initialValue={false}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value={false}>
                        {t(`Vehicles.TransferModal.TansferBothVehicleAndDevice`, {
                          company: selectedCompanyName
                        })}
                      </Radio>
                      <Radio value={true}>
                        {t(`Vehicles.TransferModal.UnpairAndTransfer`, {
                          company: selectedCompanyName
                        })}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )
        };
      });
  }, [devices, selectedCompanyName]);

  return (
    <Modal
      title={t('Vehicles.TransferModal.Title')}
      open={isOpen}
      centered={true}
      width={1000}
      onCancel={onClose}
      destroyOnClose={true}
      footer={footer()}
    >
      <Form
        layout="vertical"
        form={vehicleTransferForm}
        name="deviceTransferForm"
        onFieldsChange={() => {
          let isAnyDevicePaired = false;
          devices
            .filter(i => i.checked)
            .forEach(d => {
              isAnyDevicePaired =
                isAnyDevicePaired || !vehicleTransferForm.getFieldValue('deviceOnly_' + d.value);
            });

          setShowVehicleWarning(!isAnyDevicePaired);
        }}
        onFinish={() => {
          const returnObj = {
            companyId: vehicleTransferForm.getFieldValue('targetCompany'),
            agreements: vehicleTransferForm.getFieldValue('agreements'),
            devices: []
          };

          devices
            .filter(i => i.checked)
            .forEach(d => {
              returnObj.devices.push({
                id: d.value,
                deviceOnly: vehicleTransferForm.getFieldValue('deviceOnly_' + d.value) ?? false
              });
            });

          onComplete(returnObj);
        }}
      >
        <Row gutter={[64, 0]}>
          {showVehicleWarning && (
            <Col xs={24}>
              <Form.Item>
                <Alert
                  message={t('Vehicles.Form.SkipCompanyTransferWithoutDevice')}
                  type="warning"
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={12}>
            <Form.Item
              name="currentCompany"
              labelAlign={'left'}
              label={t(`Vehicles.TransferModal.CurrentCompany`)}
              colon={false}
            >
              <Select
                name="currentCompanySelect"
                disabled={true}
                options={(companies || []).map(e => {
                  return { value: e.id, label: e.name };
                })}
                loading={isFetchingCompany || isFetchingAgreement}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="targetCompany"
              labelAlign={'left'}
              label={t(`Vehicles.TransferModal.DestinationCompany`)}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('Devices.TransferModal.DestinationCompanyRequired')
                }
              ]}
            >
              <Select
                name="targetCompanySelect"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                options={(companies || [])
                  .filter(i => i.id !== vehicle.companyId)
                  .map(e => {
                    return { value: e.id, label: e.name };
                  })}
                loading={isFetchingCompany || isFetchingAgreement}
              />
            </Form.Item>
          </Col>
        </Row>
        {vehicle?.devices && <Tabs defaultActiveKey="1" items={deviceTab} onChange={() => {}} />}
        <Row gutter={[64, 0]}>
          <Col span={24}>
            <Row className={styles.settingDiv}>
              <Col span={24}>
                <Checkbox
                  onChange={(e, a) => {
                    setAssignAgreement(e.target.checked);
                  }}
                >
                  {t(`Vehicles.TransferModal.AssignAgreement`)}
                </Checkbox>
              </Col>
              {assignAgreement && (
                <Col span={24} className={styles.agreementDiv}>
                  <Form.Item
                    name="agreements"
                    labelAlign={'left'}
                    colon={false}
                    shouldUpdate
                    rules={[
                      {
                        required: true,
                        message: t('Vehicles.TransferModal.AgreementRequired')
                      }
                    ]}
                  >
                    <Table
                      bordered
                      loading={isFetchingCompany || isFetchingAgreement}
                      columns={columns}
                      rowKey={'id'}
                      rowSelection={{
                        ...{
                          onChange: (selectedRowKeys, selectedRows) => {
                            vehicleTransferForm.setFieldValue(
                              'agreements',
                              selectedRows.map(i => i.id)
                            );
                          }
                        }
                      }}
                      dataSource={agreementData?.map(i => i.agreements)?.flat()}
                      pagination={false}
                      scroll={{ y: 300 }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
