import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabNavLink } from 'components/nav/NavLinks';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { Can } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { InstallationLogs } from './InstallationLogs';
import { InstallationAuditLogsView } from './InstallationAuditLogsView';
import DownloadAuditLogsView from './DownloadAuditLogsView';
import InstallationAuditLogsPhotos from './InstallationAuditLogsPhotos';

export const InstallationAuditLogsRouter = () => {
  const { t } = useTranslation();

  return (
    <>
      <Can onFail={<NoAccessBody />}>
        <Switch>
          <Route exact path={['/supportTools/installationAuditLog']}>
            <InstallationLogs />
          </Route>
          <Route path={['/supportTools/installationAuditLog/view']}>
            <InstallationAuditLogsView />
          </Route>
          <Route path={['/supportTools/installationAuditLog/logview/export/id']}>
            <DownloadAuditLogsView />
          </Route>
          <Route path={['/supportTools/installationAuditLog/photos/:supportType/:id']}>
            <InstallationAuditLogsPhotos />
          </Route>
        </Switch>
      </Can>
    </>
  );
};

export default InstallationAuditLogsRouter;
