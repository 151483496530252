import React, { useMemo } from 'react';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { writeFile, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';

export const FileType = {
  EXCEL: 'xlsx',
  CSV: 'csv'
};

const defaultFormater = ({ columnName, columnValue, columnDataIndex }) => ({
  columnName,
  columnValue
});

export const DeviceListExportButton = ({
  id,
  type = 'primary',
  devices,
  columns,
  fileName,
  formater = defaultFormater
}) => {
  const { t } = useTranslation();
  const menu = useMemo(
    () => ({
      items: [
        {
          key: '1',
          label: t('Common.ExportToCSV')
        },
        {
          key: '2',
          label: t('Common.ExporttoExcel')
        }
      ],
      onClick: ({ key }) => {
        if (key === '1') {
          exportCSVFile({
            fileName: fileName || t('Common.Devices'),
            devices,
            columns,
            formater,
            sheetName: t('Common.Devices')
          });
        } else if (key === '2') {
          exportExcelFile({
            fileName: fileName || t('Common.Devices'),
            devices,
            columns,
            formater,
            sheetName: t('Common.Devices')
          });
        }
      }
    }),
    [t, devices, columns, fileName, formater]
  );

  return (
    <Dropdown disabled={!devices?.length} menu={menu} placement="bottomRight">
      <Button id={id} size="medium" type={type}>
        <Space>
          {t('Common.Export')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const exportCSVFile = ({
  devices = [],
  columns = [],
  fileName,
  sheetName,
  formater = defaultFormater
}) => {
  const workbook = utils.book_new();
  const sheet = utils.json_to_sheet(
    devices.map(device =>
      columns.reduce((cols, col) => {
        const { columnName, columnValue } = formater({
          columnName: col.title,
          columnValue: device?.[col.dataIndex],
          columnDataIndex: col.dataIndex
        });
        return {
          ...cols,
          [columnName]: Array.isArray(columnValue) ? columnValue.join(', ') : columnValue
        };
      }, {})
    )
  );
  utils.book_append_sheet(workbook, sheet, sheetName);
  writeFile(workbook, `${fileName}.${FileType.CSV}`);
};

const exportExcelFile = ({
  devices = [],
  columns = [],
  fileName,
  sheetName,
  formater = defaultFormater
}) => {
  const workbook = utils.book_new();
  const merges = [],
    cols = [];
  const worksheetJson = devices.reduce((acc, device, deviceIndex) => {
    const deviceCols = {},
      deviceRowSpan = {
        rowspan: 1,
        baseColName: null
      };
    columns.forEach(col => {
      const { columnName, columnValue } = formater({
        columnName: col.title,
        columnValue: device?.[col.dataIndex],
        columnDataIndex: col.dataIndex
      });
      if (Array.isArray(columnValue) && columnValue.length >= deviceRowSpan.rowspan) {
        deviceRowSpan.rowspan = columnValue.length;
        deviceRowSpan.baseColName = columnName;
      }
      deviceCols[columnName] = columnValue;
    });
    if (deviceRowSpan.baseColName) {
      const deviceRows = deviceCols[deviceRowSpan.baseColName].map(rowColValue => ({
        ...deviceCols,
        [deviceRowSpan.baseColName]: rowColValue
      }));

      Object.keys(deviceRows[0]).forEach((colName, colIndex) => {
        if (colName === deviceRowSpan.baseColName) {
          cols.push({
            wpx: 250
          });
        } else {
          merges.push({
            s: { r: deviceRows.length * deviceIndex + 1, c: colIndex },
            e: { r: deviceRows.length * (deviceIndex + 1), c: colIndex }
          });
          cols.push({
            wpx: 120
          });
        }
      });
      return [...acc, ...deviceRows];
    } else {
      return [...acc, deviceCols];
    }
  }, []);
  const worksheet = utils.json_to_sheet(worksheetJson);
  worksheet['!merges'] = merges;
  worksheet['!cols'] = cols;
  utils.book_append_sheet(workbook, worksheet, sheetName);
  writeFile(workbook, `${fileName}.${FileType.EXCEL}`);
};
