import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { fetchImageBlobs } from './fetchImageBlobs';
import ImageDocument from './ImageDocument';

export const ImagePdfGenerator = async (attachments, localization, handleProgress, title) => {
  try {
    const res = await fetchImageBlobs(attachments, handleProgress);
    const blob = await pdf(
      <ImageDocument imageBlobs={res} localization={localization} title={title} />
    ).toBlob();
    saveAs(blob, `${title}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
