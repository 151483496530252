import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useTranslation } from 'react-i18next';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { Button, Space, Table, Badge } from 'antd';
import { DateRangePicker } from 'components/ant';
import { Select } from 'components/ant';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify, faFilter } from '@fortawesome/free-solid-svg-icons';
import { get, union, uniq } from 'lodash';
import { exportFile } from 'components/excelFile';
import styles from 'components/tn/grid/grid.module.scss';
import moment from 'moment';
import { BUTTON_IDS } from 'utils/globalConstants';
import i18n from 'i18next';
import { Comparators } from 'utils/sorting';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { formatOdometer } from 'features/meters';

import {
  useInstallationLogsDatabyCompany,
  fetchinstallationDetailsByCompany,
  setInstallationLogsViewData
} from 'features/installation_logs/InstallationLogsSlice';
import { useFleets, useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
import { prepareDataForMultiselect } from 'utils/filters';
import { ActionMenu } from 'components/actionMenu';
import dayjs from 'dayjs';
import style from '../../Scorecard/Scorecard.module.scss';
import { useBranches } from 'features/locations/locationsSlice';
import style2 from './InstallationLog.module.scss';
import { useUserGridSettings, updateUserGridSettings } from 'features/user/userGridSettingsSlice';
import { FilterColumnsModal } from './FilterColumnsModal';
import { ViewsConfigModal } from './ViewsConfigModal';
import {
  getIncludedColumns,
  getSort,
  getViewsGridFilters
} from '../../../features/tracking/trackingGridSettings';
import { ColumnTypes } from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { sortBy, groupBy } from 'lodash';
import { applyViewsGridFilters } from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { LoadingTable } from 'components/grid/LoadingTable';
import Auth from 'utils/auth';

export const SupportTypes = () => {
  return [
    { value: SupportTypesEnum.DEVICE, label: i18n.t('Common.Device') },
    { value: SupportTypesEnum.VEHICLE, label: i18n.t('Common.Vehicle') },
    {
      value: SupportTypesEnum.ENGINEER,
      label: i18n.t('SupportToolsELD.InstallationLogsColumns.Engineer')
    }
  ];
};

export const ColumnKeys = {
  FLEET: 'fleet',
  VEHICLE: 'vehiclename',
  ENGINEER: 'engineer',
  WORKORDER: 'workorder',
  VIN: 'vin',
  REGISTRATION: 'registration',
  MAKE: 'make',
  MODEL: 'model',
  YEAR: 'manufacturedOn',
  ODOMETER: 'odometer',
  ENGINEHOURS: 'engineHours',
  SERIALNO: 'serialNumber',
  IMEI: 'imei',
  DEVICETYPE: 'devicename',
  DATEORTIME: 'dateortime',
  OPERATIONTYPE: 'action',
  OPERATIONSTATUS: 'operationstatus',
  OPERATIONSUBTYPE: 'subType',
  OPERATIONSUBTYPESTATUS: 'operationsubtypestatus',
  INSTALLNOTES: 'notes',
  ACTIONS: 'actions'
};

export const getAllColumns = (supportType, localization) => {
  const columns = [
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Fleet'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.FLEET,
      key: ColumnKeys.FLEET,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.FLEET),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData.fleet || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Vehicle'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.VEHICLE,
      key: ColumnKeys.VEHICLE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.VEHICLE),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.vehiclename || '';
      },
      fixed: supportType === SupportTypesEnum.VEHICLE && 'left',
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Engineer'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.ENGINEER,
      key: ColumnKeys.ENGINEER,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.ENGINEER),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.engineer || '';
      },
      fixed: supportType === SupportTypesEnum.ENGINEER && 'left',
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.WorkOrder'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.WORKORDER,
      key: ColumnKeys.WORKORDER,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.WORKORDER),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.workorder || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.VIN'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.VIN,
      key: ColumnKeys.VIN,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.VIN),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.vin || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Registration'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.REGISTRATION,
      key: ColumnKeys.REGISTRATION,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.REGISTRATION),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.registration || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Make'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.MAKE,
      key: ColumnKeys.MAKE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.MAKE),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.make || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Model'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.MODEL,
      key: ColumnKeys.MODEL,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.MODEL),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.model || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Year'),
      type: ColumnTypes.Date,
      dataIndex: ColumnKeys.YEAR,
      key: ColumnKeys.YEAR,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.YEAR),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        const dateValue = rowData?.manufacturedOn;
        return dateValue ? format(dateValue, 'YYYY') : '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Odometer'),
      type: ColumnTypes.Number,
      dataIndex: ColumnKeys.ODOMETER,
      key: ColumnKeys.ODOMETER,
      width: '150px',
      sorter: Comparators.Number(ColumnKeys.ODOMETER),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        const odometervalue = rowData?.odometer;
        return odometervalue ? formatOdometer(localization, odometervalue) : '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.EngineHours'),
      type: ColumnTypes.Number,
      dataIndex: ColumnKeys.ENGINEHOURS,
      key: ColumnKeys.ENGINEHOURS,
      width: '150px',
      sorter: Comparators.Number(ColumnKeys.ENGINEHOURS),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.engineHours || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.SerialNo'),
      type: ColumnTypes.Number,
      dataIndex: ColumnKeys.SERIALNO,
      key: ColumnKeys.SERIALNO,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.SERIALNO),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.serialNumber || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.IMEI'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.IMEI,
      key: ColumnKeys.IMEI,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.IMEI),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.imei || '';
      },
      fixed: supportType === SupportTypesEnum.DEVICE && 'left',
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.DeviceType'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.DEVICETYPE,
      key: ColumnKeys.DEVICETYPE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.DEVICETYPE),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.devicename || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.DateOrTime'),
      type: ColumnTypes.Date,
      dataIndex: ColumnKeys.DATEORTIME,
      key: ColumnKeys.DATEORTIME,
      width: '150px',
      sorter: Comparators.Date(ColumnKeys.DATEORTIME),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        const dateValue = rowData?.dateortime;
        return dateValue ? format(dateValue, localization.formats.time.formats.dby_imp) : '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.OperationType'),
      type: ColumnTypes.OperationType,
      dataIndex: ColumnKeys.OPERATIONTYPE,
      key: ColumnKeys.OPERATIONTYPE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.OPERATIONTYPE),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.action === 'Test' ? 'Validate' : rowData?.action || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.OperationStatus'),
      type: ColumnTypes.OperationStatus,
      dataIndex: ColumnKeys.OPERATIONSTATUS,
      key: ColumnKeys.OPERATIONSTATUS,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.OPERATIONSTATUS),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.operationstatus || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.OperationSubtype'),
      type: ColumnTypes.OperationSubType,
      dataIndex: ColumnKeys.OPERATIONSUBTYPE,
      key: ColumnKeys.OPERATIONSUBTYPE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.OPERATIONSUBTYPE),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.subType || '';
      },
      isFilterable: true
    },
    // {
    //   title: i18n.t('SupportToolsELD.InstallationLogsColumns.OperationSubtypeStatus'),
    //   type: ColumnTypes.OperationSubTypeStatus,
    //   dataIndex: ColumnKeys.OPERATIONSUBTYPESTATUS,
    //   key: ColumnKeys.OPERATIONSUBTYPESTATUS,
    //   width: '250px',
    //   sorter: Comparators.String(ColumnKeys.OPERATIONSUBTYPESTATUS),
    //   isSearchable: true,
    //   isConfigurable: true,
    //   isSelectedByDefault: true,
    //   render: (value, rowData, rowIndex) => {
    //     return rowData?.operationsubtypestatus || '';
    //   },
    //   isFilterable: true
    // },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.InstallNotes'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.INSTALLNOTES,
      key: ColumnKeys.INSTALLNOTES,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.INSTALLNOTES),
      isSearchable: true,
      isConfigurable: true,
      isSelectedByDefault: true,
      render: (value, rowData, rowIndex) => {
        return rowData?.notes || '';
      },
      isFilterable: true
    },
    {
      title: i18n.t('SupportToolsELD.InstallationLogsColumns.Actions'),
      dataIndex: ColumnKeys.ACTIONS,
      key: ColumnKeys.ACTIONS,
      width: '100px',
      fixed: 'right',
      isFilterable: false
    }
  ];

  if (supportType === SupportTypesEnum.VEHICLE) {
    var vehicleColumn = columns.find(col => col.key === ColumnKeys.VEHICLE);
    var filteredColumns = columns.filter(col => col.key !== ColumnKeys.VEHICLE);
    return [vehicleColumn, ...filteredColumns];
  }
  if (supportType === SupportTypesEnum.DEVICE) {
    var deviceColumn = columns.find(col => col.key === ColumnKeys.IMEI);
    var filteredColumns = columns.filter(col => col.key !== ColumnKeys.IMEI);
    return [deviceColumn, ...filteredColumns];
  }
  if (supportType === SupportTypesEnum.ENGINEER) {
    var engineerColumn = columns.find(col => col.key === ColumnKeys.ENGINEER);
    var filteredColumns = columns.filter(col => col.key !== ColumnKeys.ENGINEER);
    return [engineerColumn, ...filteredColumns];
  }
  return columns;
};

export const ColumnSelector = () => {
  return [
    {
      title: i18n.t('SupportToolsELD.SelectColumns'),
      dataIndex: 'colTitle',
      key: 'colKey'
    }
  ];
};

export const getValue = (row, column) => {
  if (column.render) {
    return column.render(row[column.dataIndex], row);
  } else {
    return row[column.dataIndex];
    // return getRowValue(row, column.dataIndex);
  }
};

const getRowValue = (row, dataIndex) => {
  if (!dataIndex) {
    return null;
  }

  if (typeof dataIndex === 'string') {
    return row[dataIndex]?.toString();
  } else if (Array.isArray(dataIndex) && dataIndex.length > 0) {
    return get(row, dataIndex.join('.'))?.toString();
  } else {
    return row[dataIndex.toString()]?.toString();
  }
};

export const prepareFileForExcelExport = (data, supportType) => {
  const { tableData, localization } = data;
  const pathName = `${window.location.origin}/supportTools/installationAuditLog/photos/${supportType}`;
  const rows = tableData
    ? tableData.map(entry => {
        const row = {
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Fleet')]: entry?.fleet || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Vehicle')]: entry?.vehiclename || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Engineer')]: entry?.engineer || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.WorkOrder')]: entry?.workorder || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.VIN')]: entry?.vin || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Registration')]:
            entry?.registration || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Make')]: entry?.make || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Model')]: entry?.model || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Year')]: entry?.manufacturedOn
            ? moment(entry?.manufacturedOn).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.Odometer')]: entry?.odometer || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.EngineHours')]: entry?.engineHours || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.SerialNo')]: entry?.serialNumber || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.IMEI')]: entry?.imei || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.DeviceType')]: entry?.devicename || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.DateOrTime')]: entry?.createdAt
            ? moment(entry?.createdAt).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.OperationType')]:
            entry?.action === 'Test' ? 'Validate' : entry?.action || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.OperationStatus')]:
            entry?.operationstatus || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.OperationSubtype')]:
            entry?.subType || '',
          // [i18n.t('SupportToolsELD.InstallationLogsColumns.OperationSubtypeStatus')]:
          //   entry?.operationsubtypestatus || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.InstallNotes')]: entry?.notes || '',
          [i18n.t('SupportToolsELD.InstallationLogsColumns.InstallationPhotos')]: {
            l: {
              Target: `${pathName}/${entry.key}`,
              Tooltip: `${i18n.t('SupportToolsELD.InstallationLogsColumns.ViewPhotos')}`
            },
            v: `${i18n.t('SupportToolsELD.InstallationLogsColumns.ViewPhotos')}`
          }
        };

        return row;
      })
    : [];

  return rows;
};

export const GRID_SETTING_KEY = 'installationlogs.vehicleGrid';

export const DEFAULT_VIEW_CONFIG = {
  schemaVersion: 1,
  selectedView: 'Default',
  views: {
    Default: {
      includedColumns: [
        { key: 'fleet', width: 150 },
        { key: 'vehiclename', width: 150 },
        { key: 'engineer', width: 150 },
        { key: 'workorder', width: 150 },
        { key: 'vin', width: 150 },
        { key: 'registration', width: 150 },
        { key: 'make', width: 150 },
        { key: 'model', width: 150 },
        { key: 'manufacturedOn', width: 150 },
        { key: 'odometer', width: 150 },
        { key: 'engineHours', width: 150 },
        { key: 'serialNumber', width: 150 },
        { key: 'imei', width: 150 },
        { key: 'devicename', width: 150 },
        { key: 'dateortime', width: 150 },
        { key: 'action', width: 150 },
        { key: 'operationstatus', width: 150 },
        { key: 'subType', width: 150 },
        // { key: 'operationsubtypestatus', width: 150 },
        { key: 'notes', width: 150 }
      ],
      columns: [
        'fleet',
        'vehiclename',
        'engineer',
        'workorder',
        'vin',
        'registration',
        'make',
        'model',
        'manufacturedOn',
        'odometer',
        'engineHours',
        'serialNumber',
        'imei',
        'devicename',
        'dateortime',
        'action',
        'operationstatus',
        'subType',
        //  'operationsubtypestatus',
        'notes'
      ],
      sort: {
        column: 'lastUpdate',
        order: 'descend'
      },
      groupBy: [],
      filterColumns: [],
      filteredSupportType: '',
      filteredSearch: '',
      filterEvents: {
        includeAllEventTypes: true,
        includedEventTypes: []
      },
      filterFleets: {
        includeAllFleets: true,
        includedFleetIds: [],
        companyId: null
      },
      eventsGroupByTrips: true
    }
  }
};

export const InstallationLogs = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();

  const history = useHistory();
  const PATHS = {
    SUMMARY_VIEW: '/supportTools/installationAuditLog/view'
  };

  const [searchText, setSearchText] = useState(null);
  const devicesFeatureFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.installationAuditLog.flag
  });
  const fleets = useFleets();
  const branches = useBranches();
  const isFetchingFleets = isFleetsFetching();

  const [filterFleets, setFilterFleets] = useState([]);
  const [filterBranches, setFilterBranches] = useState([]);
  const [showColumnsFilterModal, setShowColumnsFilterModal] = useState(false);

  const { loading, installationLogsDataByCompany } = useInstallationLogsDatabyCompany();

  const [supportType, setSupportType] = useState(SupportTypesEnum.VEHICLE);

  const [summaryDataInit, setSummaryDataInit] = useState(false);
  const company = useCurrentCompany();
  const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const [allData, setAllData] = useState(installationLogsDataByCompany);
  const [tableData, setTableData] = useState(allData && allData);
  const datePickerRef = useRef();
  const [statusFilter, setStatusFilter] = useState(null);
  const [statusData, setStatusData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showFilterColumnsModal, setShowFilterColumnsModal] = useState(false);
  const gridSettingsKey = null;
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const [columns, setColumns] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [newUserGridSettings, setNewUserGridSettings] = useState(null);

  const [dateFrom, setDateFrom] = useState(
    history.location.state?.dateFrom ||
      moment()
        .add(-1, 'year')
        .format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    history.location.state?.dateTo ||
      moment()
        .startOf('day')
        .format('YYYY-MM-DD')
  );

  const [allColumns, setAllColumns] = useState(getAllColumns(supportType, localization));

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  const handleDateRangeClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setAllColumns(getAllColumns(supportType, localization));
    setAllData(installationLogsDataByCompany);
    getOperationStatus(installationLogsDataByCompany);
    allData && getOperationTypeDropdown();
    allData && getOperationSubTypeDropdown();
    allData && getOperationSubTypeStatusDropdown();
  }, [supportType, installationLogsDataByCompany, allData]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && fleet.company.id === company?.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), null));
  }, [fleets, t]);

  useEffect(() => {
    if (!summaryDataInit || company.id !== selectedCompany) {
      setSummaryDataInit(true);
      setSelectedCompany(company.id);
    }
    dispatch(fetchinstallationDetailsByCompany(dateFrom, dateTo));
  }, [dispatch, company, summaryDataInit, selectedCompany, dateFrom, dateTo]);

  useEffect(() => {
    dispatch(setPageTitle(`${t('SupportToolsELD.InstallationAuditLogTitle')}`));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    const searchTextValue = Auth.getSearchText();
    const supportTypeValue = Auth.getSupportTypeValue();
    const onStatusValue = Auth.getOnStatus();

    if (supportTypeValue) {
      setSupportType(supportTypeValue);
    }
    if (searchTextValue) {
      setSearchText(searchTextValue);
    }
    if (onStatusValue) {
      setStatusFilter(onStatusValue);
    }
    const orderMap = new Map(allColumns.map((item, index) => [item.key, index]));

    // Sort the second array based on the order defined in the map
    const sortedArray = getUserColumns(allColumns, userGridSettings)?.sort((a, b) => {
      return orderMap.get(a.key) - orderMap.get(b.key);
    });
    setColumns(sortedArray);
  }, [allColumns, userGridSettings]);
  const getUserColumns = (allColumns, userGridSettings) => {
    if (userGridSettings && userGridSettings.lastFetched) {
      let userColumns = [];
      let columnsFromConfig = [];
      let newColumnsSchema = true;
      if (userGridSettings && !userGridSettings.config) {
        columnsFromConfig = DEFAULT_VIEW_CONFIG.views['Default'].includedColumns;
      } else {
        let result = getIncludedColumns(userGridSettings);
        columnsFromConfig = result.columnsFromConfig;
        newColumnsSchema = result.newColumnsSchema;
      }

      // let sort = getSort(userGridSettings, columnsFromConfig);

      // Always add non configurable fixed left columns on the beginning
      const fixedLeftColumns = allColumns.filter(
        item => item.fixed === 'left' && item.isConfigurable
      );
      userColumns.push(...fixedLeftColumns);

      // Add all the columns from the config (or defaults) in the right order
      columnsFromConfig.forEach(columnFromConfig => {
        let key = newColumnsSchema ? columnFromConfig.key : columnFromConfig;

        const foundColumn = allColumns.find(x => x.key === key);
        const alreadyAdded = userColumns.find(x => x.key === key);
        if (foundColumn && !alreadyAdded) {
          const userColumn = { ...foundColumn };

          // Add widths for resize feature
          if (newColumnsSchema) {
            userColumn.width = columnFromConfig.width;
          }
          userColumns.push(userColumn);
        }
      });

      // Always add non configurable fixed right columns on the end
      const fixedRightColumns = allColumns.filter(
        item => item.fixed === 'right' && !item.isConfigurable
      );
      userColumns.push(...fixedRightColumns);
      return userColumns;
    }
    return null;
  };

  const handleSearchChange = text => {
    setSearchText(text);
    Auth.setSearchText(text);
  };

  const handleSupportTypeChange = value => {
    if (value === SupportTypesEnum.DEVICE) {
      setSupportType(SupportTypesEnum.DEVICE);
    } else if (value === SupportTypesEnum.VEHICLE) {
      setSupportType(SupportTypesEnum.VEHICLE);
    } else {
      setSupportType(SupportTypesEnum.ENGINEER);
    }
    Auth.setSupportTypeValue(value);
  };

  const PrepareColumnsForSelector = () => {
    const allColumns = getAllColumns(supportType, localization);

    const columns = allColumns?.map((col, index) => ({
      key: index,
      colTitle: col.title,
      colKey: col.key
    }));
    return columns.filter(c => c.colKey !== ColumnKeys.ACTIONS);
  };

  const preparedColumns = PrepareColumnsForSelector();

  const [filterColumns, setFilterColumns] = useState(
    preparedColumns.filter(x =>
      getAllColumns(supportType, localization).find(z => z.key === x.colKey)
    )
  );

  const [selectedColumns, setSelectedColumns] = useState({
    selectedRowKeys: filterColumns?.map(c => c.key),
    selectedRows: filterColumns
  });

  useEffect(() => {
    const columnData = PrepareColumnsForSelector();
    if (filterColumns.length === 0) {
      setFilterColumns(columnData);
    }
  }, [filterColumns]);

  const getAccessableColumns = columns => {
    return columns.filter(column => {
      const canAccess = column.hasOwnProperty('canAccess') ? column.canAccess() : true;
      return canAccess;
    });
  };

  const [numFilters, setNumFilters] = useState(0);
  const { allAccessableColumns, viewsGridFilters } = useMemo(() => {
    // put in memo so columns and viewsGridFilters don't change every render call
    const allAccessableColumns = getAccessableColumns(allColumns);
    const viewsGridFilters = getViewsGridFilters(userGridSettings, allAccessableColumns);
    setNumFilters(viewsGridFilters?.length ?? 0);
    return {
      allAccessableColumns,
      viewsGridFilters
    };
  }, [userGridSettings, company]);

  useEffect(() => {
    const data = PrepareDataForTable({
      allData,
      searchText,
      filterFleets,
      filterBranches,
      statusFilter,
      selectedFilters,
      viewsGridFilters
    });
    setTableData(data);
  }, [
    allData,
    searchText,
    supportType,
    filterFleets,
    filterBranches,
    statusFilter,
    selectedFilters,
    viewsGridFilters
  ]);
  const PrepareDataForTable = ({
    allData,
    searchText,
    filterFleets,
    filterBranches,
    statusFilter,
    selectedFilters,
    viewsGridFilters
  }) => {
    var allDataTemp = allData?.filter(item => item?.status !== 'Started');
    var checkedFleets = [];
    var checkedBranches = [];
    var returnData = [];

    const searchableColumns = getAllColumns(supportType, localization)?.filter(
      col => col.isSearchable
    );

    const getSummaryMenuItems = ({ index, item, t = null, history = null }) => {
      return [
        {
          name: t('Common.EllipsisButton.View'),
          onClick: () => {
            new Promise((resolve, reject) => {
              dispatch(setInstallationLogsViewData({ ...item, supportType: supportType }));
              resolve();
            }).then(() => {
              history.push(`${PATHS.SUMMARY_VIEW}/${supportType}/${item.id}`, {
                data: {
                  rowData: item,
                  supportType: supportType,
                  //entityName: item?.[supportType]?.name
                  entityName:
                    supportType === SupportTypesEnum.ENGINEER
                      ? item?.[SupportTypesEnum.USER]?.name
                      : item?.[supportType]?.name || ''
                }
              });
            });
          },
          entity: 'VIEW',
          id: 'btn_summaryView'
        }
      ];
    };

    returnData = allDataTemp?.map((entry, index) => {
      return {
        key: entry.id,
        [ColumnKeys.FLEET]:
          entry?.fleets && entry.fleets.length > 0 ? entry.fleets.map(f => f.name)?.join(', ') : '',
        [ColumnKeys.VEHICLE]: entry.vehicle?.name,
        [ColumnKeys.ENGINEER]: entry?.user?.name,
        [ColumnKeys.WORKORDER]: entry.vehicle?.workorder,
        [ColumnKeys.VIN]: entry.vehicle?.vin,
        [ColumnKeys.REGISTRATION]: entry.vehicle?.registration,
        [ColumnKeys.MAKE]: entry.vehicle?.make,
        [ColumnKeys.MODEL]: entry.vehicle?.model,
        [ColumnKeys.YEAR]: entry.vehicle?.manufacturedOn,
        [ColumnKeys.ODOMETER]: entry.vehicle?.odometer,
        [ColumnKeys.ENGINEHOURS]: entry.vehicle?.engineHours,
        [ColumnKeys.SERIALNO]: entry.device?.serialNumber,
        [ColumnKeys.IMEI]: entry.device?.imei,
        [ColumnKeys.DEVICETYPE]: entry?.device?.name,
        [ColumnKeys.DATEORTIME]: entry?.createdAt,
        [ColumnKeys.OPERATIONTYPE]: entry?.action,
        [ColumnKeys.OPERATIONSTATUS]: entry?.status,
        [ColumnKeys.OPERATIONSUBTYPE]: entry?.subType,
        [ColumnKeys.OPERATIONSUBTYPESTATUS]: entry?.[supportType]?.status,
        [ColumnKeys.INSTALLNOTES]: entry?.notes,
        fleets: entry?.fleets,
        locations: entry?.location,
        [ColumnKeys.ACTIONS]: (
          <ActionMenu
            items={getSummaryMenuItems({
              index: index,
              item: entry,
              history,
              t
            })}
            data={entry}
          />
        )
      };
    });

    if (Array.isArray(filterFleets)) {
      checkedFleets = filterFleets.filter(f => f.checked === true);
    }

    if (Array.isArray(filterBranches)) {
      checkedBranches = filterBranches.filter(f => f.checked === true);
    }

    if (checkedFleets.length > 0 && checkedFleets.filter(f => f.id === 0).length === 0) {
      let res = [];
      res = returnData?.filter(el => {
        return checkedFleets?.find(element => {
          if (element.id === -1 && !el.fleets) return true;

          if (el.fleets) {
            return el.fleets.find(f => {
              return (
                element.id === f.id || (element.id === -1 && !f.id) || element.label === f.name
              );
            });
          }
        });
      });
      returnData = res;
    } else if (checkedFleets.length === 0) {
      returnData = [];
    }

    if (checkedBranches.length > 0 && checkedBranches.filter(f => f.id === 0).length === 0) {
      let res = [];
      res = returnData?.filter(el => {
        return checkedBranches.find(element => {
          if (element.id === -1 && !el.user?.locations) return true;

          if (el.user?.locations) {
            return el.user?.locations.find(b => {
              return element.id === b.id || (element.id === -1 && !b.id);
            });
          }
        });
      });

      returnData = res;
    } else if (checkedBranches.length === 0) {
      returnData = [];
    }

    // Apply status filter
    const filterDataByStatus = (data, statusId) => {
      if (statusId === i18n.t('Common.AllStatuses')) {
        return data;
      }
      return data && data?.filter(item => item?.operationstatus?.toUpperCase() === statusId);
    };

    returnData = filterDataByStatus(returnData, statusFilter);

    let selectedChildFilters = [];
    let filtered = null;

    for (let key in selectedFilters) {
      selectedChildFilters = selectedChildFilters.concat(
        selectedFilters[key]?.children.filter((node, index) => index > 0 && node.checked)
      );
    }
    const hasSelectedChildFilters = selectedChildFilters.length > 0;
    selectedChildFilters = groupBy(selectedChildFilters, 'parent');

    // apply filters
    if (hasSelectedChildFilters) {
      filtered = filtered?.filter(row => {
        let matchesFilters = true;
        for (let key in selectedChildFilters) {
          matchesFilters =
            matchesFilters &&
            selectedChildFilters[key]?.find(
              filter => getRowValue(row, filter.dataKey) === filter.label
            );
        }
        return matchesFilters;
      });
    }

    filtered = returnData?.filter(row => {
      // If there's no search text, return true for all rows
      if (!searchText?.trim()) {
        return true;
      }
      // Convert search text to lower case for case-insensitive comparison
      const lowerSearchText = searchText.trim().toLowerCase();

      // Check if any column value includes the search text
      return searchableColumns.some(col =>
        row[col.dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(lowerSearchText)
      );
    });

    // apply viewsGridFilters
    if (viewsGridFilters && viewsGridFilters.length > 0) {
      filtered = applyViewsGridFilters(viewsGridFilters, filtered, columns, getRowValue);
    }
    // setFilteredData(filtered);
    return filtered;
  };

  useEffect(() => {
    const companyBranches = branches.filter(
      branch =>
        branch.id &&
        (branch.id === -1 ||
          (branch.companyId && branch.companyId === company?.id && branch.name.trim('') !== ''))
    );

    const branchOptions = prepareDataForMultiselect(companyBranches, t('Common.AllBranches'), null);
    const noBranch = branchOptions?.find(b => b.id === -1);
    if (noBranch) {
      noBranch.name = t('Users.NoBranch');
    }
    setFilterBranches(branchOptions);
  }, [branches, t]);

  const handleExportExcel = () => {
    const columnWidth = Array.apply(null, { length: 40 }).map(() => ({ wch: 25 }));

    const data = prepareFileForExcelExport(
      {
        tableData,
        localization,
        dateFormat: localization.formats.time.formats.dby_imsp
      },
      supportType
    );
    dispatch(
      exportFile(data, {
        width: columnWidth,
        title: i18n.t('SupportToolsELD.InstallationAuditLogTitle').replace(/ /g, '-'),
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  const supportTypeValue = type => {
    switch (type) {
      case SupportTypesEnum.VEHICLE:
        return t('Common.Vehicles');
      case SupportTypesEnum.DEVICE:
        return t('Common.Devices');
      case SupportTypesEnum.ENGINEER:
        return t('SupportToolsELD.InstallationLogsColumns.Engineer');
      default:
        return null; // or any default value you want to return
    }
  };

  const onStatusChange = value => {
    setStatusFilter(value);
    Auth.setOnStatus(value);
  };

  const [operationTypeData, setOperationTypeData] = useState([]);
  const [operationStatusData, setOperationStatusData] = useState([]);
  const [operationSubTypeData, setOperationSubTypeData] = useState([]);
  const [operationSubTypeStatusData, setOperationSubTypeStatusData] = useState([]);

  const getOperationStatus = () => {
    const unique = [
      ...new Set(
        allData &&
          allData
            .filter(item => item?.status !== 'Started')
            .map(item => JSON.stringify({ id: item?.status?.toUpperCase(), label: item?.status }))
      )
    ].map(item => JSON.parse(item));
    const uniqueCopy = [...unique];
    setOperationStatusData(uniqueCopy);
    unique.unshift({ id: i18n.t('Common.AllStatuses'), label: i18n.t('Common.AllStatuses') });
    setStatusFilter(unique[0]?.id);
    setStatusData(unique);
  };

  const getOperationTypeDropdown = () => {
    const unique = [
      ...new Set(
        allData &&
          allData
            .filter(item => item?.action && item.action.trim() !== '' && item.action !== 'Started')
            .map(item => item.action.toUpperCase())
            .map(action =>
              JSON.stringify({
                id: action,
                label: i18n.t(`SupportToolsELD.InstallationLogsView.${action}`)
              })
            )
      )
    ].map(item => JSON.parse(item));
    setOperationTypeData(unique);
  };

  const getOperationSubTypeDropdown = () => {
    const unique = [
      ...new Set(
        allData &&
          allData
            .filter(item => item?.subType && item?.subType?.trim() !== '')
            .map(item => item?.subType?.toUpperCase())
            .map(subType =>
              JSON.stringify({
                id: subType,
                label: i18n.t(`SupportToolsELD.InstallationLogsView.${subType}`)
              })
            )
      )
    ].map(item => JSON.parse(item));
    setOperationSubTypeData(unique);
  };
  const getOperationSubTypeStatusDropdown = () => {
    const unique = [
      ...new Set(
        allData &&
          allData
            .filter(
              item => item?.[supportType]?.status && item?.[supportType]?.status.trim() !== ''
            )
            .map(item =>
              JSON.stringify({
                id: item?.[supportType]?.status?.toUpperCase(),
                label: item?.[supportType]?.status
              })
            )
      )
    ].map(item => JSON.parse(item));
    setOperationSubTypeStatusData(unique);
  };

  const defaultDates = [dayjs().subtract(1, 'month'), dayjs()];

  const [selectedDates, setSelectedDates] = useState(defaultDates);

  const handleDateRangeChange = newRange => {
    setSelectedDates(newRange);
    const formattedDates = newRange.map(date => date.format('YYYY-MM-DD'));
    setDateFrom(formattedDates[0]);
    setDateTo(formattedDates[1]);
  };

  const startDate = dayjs().subtract(1, 'year');
  const endDate = dayjs();

  if (!startDate.isValid() || !endDate.isValid()) {
    console.error('Invalid date range');
    return null;
  }

  if (loading) return <LoadingTable nrOfRows={1} columnSizes={[100, 100, 100, 100, 100, 100]} />;

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            {devicesFeatureFlag && (
              <div style={{ borderBottom: '2px solid #f2f3f2', display: 'flex', width: '100%' }}>
                <TabNavLink
                  to="/supportTools/installationAuditLog"
                  isActive={(match, location) =>
                    location.pathname === '/supportTools/installationAuditLog'
                  }
                >
                  {t('SupportToolsELD.InstallationAuditLogTitle')}
                </TabNavLink>
              </div>
            )}
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Space size={16}>
              <Button
                type="primary"
                size="large"
                id={BUTTON_IDS.supportSummaryExcelExport}
                onClick={handleExportExcel}
                disabled={allData?.length === 0}
              >
                {t('Common.ExporttoExcel')}
              </Button>
              {/* <Button
                type="secondary"
                size="large"
                id={BUTTON_IDS.scheduledReports}
                onClick={() => console.log('ScheduledReports')}
                // disabled={isFetchingFleets}
                disabled
              >
                {t('SupportToolsELD.ScheduledReports')}
              </Button> */}
            </Space>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f2f2f2' }}>
          <FilterWrapper>
            <Space size={6}>
              <AntSearchbar onFilter={handleSearchChange} value={searchText} size={'medium'} />
              <Select
                size="default"
                //  className={`${style2.supportTypeSelector}`}
                options={SupportTypes()}
                value={supportType}
                onChange={handleSupportTypeChange}
                // className={summaryStyles.supportTypeSelector}
              />

              {supportType === SupportTypesEnum.VEHICLE ||
              supportType === SupportTypesEnum.DEVICE ? (
                <AntMultiselect
                  size={'medium'}
                  // className="multiselect"
                  title={
                    filterFleets?.some(value => !value.checked)
                      ? t('Common.Fleets')
                      : t('Common.AllFleets')
                  }
                  data={filterFleets}
                  onFilter={v => setFilterFleets(v)}
                />
              ) : (
                <AntMultiselect
                  size={'medium'}
                  title={
                    filterBranches?.some(value => !value.checked)
                      ? t('Common.Branches')
                      : t('Common.AllBranches')
                  }
                  onFilter={v => setFilterBranches(v)}
                  data={filterBranches}
                />
              )}

              <Select
                className={styles.statusesSelect}
                data={statusData && statusData}
                onSelect={onStatusChange}
                value={statusFilter}
                placeholder={t('Common.AllStatuses')}
              />
              <Badge
                offset={[-13, 3]}
                count={numFilters}
                style={{
                  minWidth: '16px',
                  height: '16px',
                  padding: '0 2px',
                  lineHeight: '16px',
                  borderRadius: '8px'
                }}
              >
                <Button
                  className={styles.filterColumnsButton}
                  id={BUTTON_IDS.showFilterColumnsModal}
                  onClick={() => {
                    setShowFilterColumnsModal(true);
                  }}
                >
                  <FontAwesomeIcon className={styles.filterIcon} icon={faFilter} />
                </Button>
              </Badge>
              <Button
                className={styles.filterColumnsButton}
                id={BUTTON_IDS.supportSummaryColumns}
                onClick={() => {
                  setShowColumnsFilterModal(true);
                }}
              >
                <FontAwesomeIcon
                  rotation={90}
                  className={styles.filterIcon}
                  icon={faAlignJustify}
                />
              </Button>
              <DateRangePicker
                className={style.dateRangePicker}
                format={localization.formats.time.formats.dby.toUpperCase()}
                defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
                onDateRangeChanged={dates => {
                  handleDateRangeChange(dates);
                }}
                onOpenChange={handleDateRangeClose}
              />
            </Space>
          </FilterWrapper>
        </div>
        <div>
          <Table
            className={`${styles.grid} ${styles.gridShowScrollbars} ${style2.table_header}`}
            columns={
              columns &&
              columns?.filter(
                c => filterColumns.find(x => x.colKey === c.key) || c.key === ColumnKeys.ACTIONS
              )
            }
            dataSource={tableData}
            pagination={{ pageSize: 25, showSizeChanger: false }}
            scroll={{ y: 'calc(100vh - 275px)', x: 'max-content' }}
            showSorterTooltip={false}
          />
        </div>
      </ContainerPageWrapper>
      {showColumnsFilterModal && (
        <ViewsConfigModal
          allColumns={allColumns}
          gridSettingsKey={gridSettingsKey}
          defaultGridConfig={DEFAULT_VIEW_CONFIG}
          isOpen={showColumnsFilterModal}
          onClose={() => {
            setShowColumnsFilterModal(false);
          }}
        />
      )}
      {showFilterColumnsModal && (
        <FilterColumnsModal
          allColumns={allColumns}
          operationTypeData={operationTypeData}
          operationStatusData={operationStatusData}
          operationSubTypeData={operationSubTypeData}
          operationSubTypeStatusData={operationSubTypeStatusData}
          gridSettingsKey={gridSettingsKey}
          defaultGridConfig={DEFAULT_VIEW_CONFIG}
          isOpen={showFilterColumnsModal}
          onClose={() => {
            setShowFilterColumnsModal(false);
          }}
        />
      )}
    </>
  );
};

export const SupportTypesEnum = {
  VEHICLE: 'vehicle',
  DEVICE: 'device',
  ENGINEER: 'engineer',
  USER: 'user'
};

export const SubTypesEnum = {
  INSTALL: 'INSTALL',
  REPLACE: 'REPLACE',
  TEST: 'TEST',
  DEINSTALL: 'DEINSTALL'
};

export const InstallMatch = ['NEWINSTALL', 'REINSTALL', 'REPAIR'];
export const ReplaceMatch = ['UPGRADE', 'REPAIRRMA'];
