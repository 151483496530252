import { useMemo } from 'react';
import { faHome, faChartLine, faNewspaper, faArchive } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  services,
  entities,
  companyFeatures,
  FeatureFlag,
  useCanOneOfRoles,
  GlobalRoles,
  useCannotOneOfRoles,
  useIQCameraUser,
  useCan
} from 'features/permissions';
import { fetchNetradyneUrl } from 'features/permissions/permissionsSlice';
import { AuthUtil } from 'features/auth/authUtil';
import { useUser, useUserKey } from 'features/user/userSlice';

import { TrackingRouter } from 'containers/Tracking/TrackingRouter';
import { Home } from 'containers/home/Home';
import { DailySnapshots } from 'containers/home/Camera/SubTabs/Snapshots/DailySnapshots';
import { InsightsRouter } from 'containers/InsightsQS/InsightsRouter';
import { Reports } from 'containers/Reports/Reports';
import { ScorecardRouter } from 'containers/Scorecard';
import { AppSettings } from 'containers/Settings/Settings';
import { MixedEnergy } from 'containers/MixedEnergy/MixedEnergy';
import { EventDetail } from 'containers/Events/EventDetail';
import { EasydocsRouter } from 'containers/Easydocs/EasydocsRouter';
import { VehicleMaintenanceRouter } from 'containers/VehicleMaintenance/VehicleMaintenanceRouter';
import { DriverManagementRouter } from 'containers/DriverManagement';
import { IntegrationsRouter } from 'containers/Integrations';
import { JourneyPlannerRouter } from 'containers/JourneyPlanner/JourneyPlannerRouter';
import { NextGenEventDetail } from 'containers/Events/NextGenEventDetail';
import { NotificationEvent } from 'containers/Events/NotificationEvent';
import { Fatigue } from 'containers/Fatigue/fatigue';
import { FatigueAu } from 'containers/Fatigue/FatigueAu';
import { ELD } from 'containers/ELD/ELD';
import { SmartJobsRouter } from 'containers/SmartJobs';
import { FBTManagerRouter } from 'containers/FBTManager';
import { MessagingRouter } from 'containers/Messaging/MessagingRouter';
import { MessagingLeftNavTitle } from 'containers/Messaging/MessagingLeftNavTitle';
import CompanyConfigRouter from 'containers/Configuration/CompanyConfig/router';
import { SmartJobsCodes } from 'containers/Configuration';
import { TCAEnrolments } from 'containers/Configuration';
import { FormsRouter } from 'containers/Configuration/Forms/FormsRouter';
import DevicesRouter from 'containers/Administration/Devices/DevicesRouter';
import { FleetsRouter } from 'containers/Administration/Fleets/FleetsRouter';
import { GeofencesRouter } from 'containers/Administration/Geofences/GeofencesRouter';
import { RolesRouter } from 'containers/Administration/Roles/RolesRouter';
import { LocationsRouter } from 'containers/Administration/Locations/LocationsRouter';
import { UsersRouter } from 'containers/Administration/Users/UsersRouter';
import { SubCompaniesRouter } from 'containers/Administration/Company/SubCompaniesRouter';
import { VehiclesRouter } from 'containers/Administration/Vehicles/VehiclesRouter';
import { WifiRouter } from 'containers/Administration/Wifi';
import { InspectionChecklistRouter } from 'containers/Configuration/InspectionChecklist/InspectionChecklistRouter';
import { CompanyAlertsRouter } from 'containers/Configuration/CompanyAlerts/router/index.js';
import { VehicleMntTypesRouter } from 'containers/Configuration/VehicleMntTypes/VehicleMntTypesRouter';
import { VehicleTypesRouter } from 'containers/Configuration/VehicleTypes/VehicleTypesRouter';
import { DriverMgtTypes } from 'containers/Configuration';
import { Router as MassManagementRouter } from 'containers/Configuration/MassManagement/Router';
import UserPreferences from 'containers/Settings/user/Preferences/Preferences';
import { UserAlertsRouter } from 'containers/Settings/user/Alerts/router';

import { MENU_IDS } from 'utils/globalConstants';
import { RUC_MANAGER_URL, STORE_URL } from 'config';
import { ViewCameraEvents } from 'containers/home/Camera/Components/ViewCameraEvents';
import { useRegions } from 'features/regions/regionsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { SupportTools } from 'containers/SupportTools/SupportTools';
import { ELDRouter } from 'containers/SupportTools/ELD/ELDRouter';
import InstallationAuditLogsRouter from 'containers/SupportTools/InstallationAuditLogs/InstallationAuditLogsRouter';

export const usePages = () => {
  const { t } = useTranslation();
  const user = useUser();
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const regions = useRegions();
  const company = useCurrentCompany();
  const can = useCan();
  const canAccessUser = useCannotOneOfRoles([GlobalRoles.IQCameraUser]);
  const accessibleAdminPage = administrationPages(t, canAccessUser).filter(page =>
    can({ ...page })
  );

  const canVltRole = useCanOneOfRoles([
    GlobalRoles.VLTAdmin,
    GlobalRoles.VLTUser,
    GlobalRoles.SiteAdmin
  ]);

  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const canAccessTripManager =
    user?.type?.code !== 'DRIVER' ||
    (user?.type?.code === 'DRIVER' &&
      can({ featureFlag: FeatureFlag.driverPortalTripManager.flag }))
      ? true
      : false;

  const vltURL = useMemo(() => {
    let link;
    if (regions == null || company == null) {
      return link;
    }
    const region = regions.find(r => r.code === company.country);
    if (region == null) {
      return link;
    }
    try {
      const configJson = JSON.parse(region.config);
      if (configJson.vlt_url) {
        link = configJson.vlt_url;
        if (user?.auth?.keycloakConfig.realm) {
          link += '/sso?realm=' + user?.auth?.keycloakConfig.realm;
        }
      } else {
        console.warn('Region config has no value for help center, use default one.');
      }
    } catch {
      console.error('Region has no config or config json has format problem.');
    }
    return link;
  }, [company, regions]);

  const onsiteTrafficUrl = useMemo(() => {
    return () =>
      AuthUtil.generateAuthUrl(user.id, user.auth.key) +
      `&url=${encodeURIComponent('/onsite_traffic/')}`;
  }, [user]);

  const netradyneUrl = () => {
    return dispatch(fetchNetradyneUrl(userKey));
  };

  const acmURL = useMemo(() => {
    let link;
    if (regions == null || company == null) {
      return link;
    }
    const region = regions.find(r => r.code === company.country);
    if (region == null) {
      return link;
    }
    try {
      const configJson = JSON.parse(region.config);
      if (configJson.acm_url) {
        link = configJson.acm_url;
      } else {
        console.warn('Region config has no value for ACM, use default one.');
      }
    } catch {
      console.error('Region has no config or config json has format problem.');
    }
    return link;
  }, [company, regions]);

  return [
    {
      everyService: services.COMMON,
      path: ['/', '/home*'],
      exact: true,
      link: '/home',
      id: MENU_IDS.home,
      title: t('Common.Home'),
      iconSvg: 'feature-home',
      icon: faHome,
      component: Home,
      navPosition: 'top'
    },
    {
      everyService: services.COMMON,
      path: '/daily-snapshots/:entity/:entityId',
      link: '/daily-snapshots',
      title: t('Home.DailySnapshot'),
      component: DailySnapshots,
      usePageTitle: true,
      navPosition: false,
      showBackButton: true
    },
    {
      oneOfServices: [services.TRACKING, services.TRACKINGHD],
      path: '/tracking*',
      link: '/tracking',
      id: MENU_IDS.tracking,
      title: t('Common.Tracking'),
      iconSvg: 'feature-tracking',
      iconClassName: 'tn-i-globe',
      component: TrackingRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true,
      featureHide: companyFeatures.HTF
    },
    {
      oneOfServices: [services.FBTMANAGER],
      everyCompanyService: services.FBTMANAGER,
      otherConditions: [() => canAccessTripManager],
      path: '/fbtmanager*',
      link: '/fbtmanager',
      id: MENU_IDS.fbtmanager,
      title: t('FBTManager.Title'),
      iconSvg: 'feature-fbt',
      iconClassName: 'tn-i-fbt',
      component: FBTManagerRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true
    },
    {
      oneOfServices: [services.SCORECARD],
      oneOfEntities: [entities.SCORECARD],
      path: '/scorecard*',
      link: '/scorecard',
      id: MENU_IDS.scorecard,
      title: t('Common.Scorecard'),
      iconSvg: 'feature-scorecard',
      iconClassName: 'tn-i-scorecard',
      component: ScorecardRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true,
      featureHide: companyFeatures.HTF
    },
    {
      everyService: services.SMARTJOBS,
      path: '/smartjobs*',
      link: '/smartjobs',
      id: MENU_IDS.smartjobs,
      title: t('Common.SmartJobs'),
      iconSvg: 'feature-smartjobs',
      icon: faArchive,
      component: SmartJobsRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true
    },
    {
      everyService: services.MESSAGING,
      path: '/messaging*',
      link: '/messaging',
      id: MENU_IDS.messaging,
      title: t('Common.Messaging'),
      titleComponent: MessagingLeftNavTitle,
      iconSvg: 'feature-messaging',
      iconClassName: 'tn-i-messages',
      component: MessagingRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true
    },
    {
      oneOfServices: [
        services.INSIGHTSBASIC,
        services.INSIGHTSSEARCH,
        services.INSIGHTSADVPROD,
        services.INSIGHTSADVSAFETY,
        services.INSIGHTSADVCOMP,
        services.INSIGHTSBUILDER,
        services.INSIGHTSVIZ,
        services.INSIGHTSAI,
        services.INSIGHTSSUSTAINABILITYSNAPSHOT,
        services.INSIGHTSSUSTAINABILITYGOALS,
        services.INSIGHTSSUSTAINABILITYFLEETS,
        services.INSIGHTSREPORTS,
        services.INSIGHTSNPI
      ],
      path: '/insights*',
      link: '/insights',
      id: MENU_IDS.insights,
      title: t('Common.Insights'),
      iconSvg: 'feature-insights',
      icon: faChartLine,
      component: InsightsRouter,
      navPosition: 'top',
      usePageTitle: true,
      useBackButton: true,
      ssoGuardFeature: 'Insights'
    },
    {
      everyService: services.COMMON,
      path: '/reports*',
      link: '/reports',
      id: MENU_IDS.reports,
      title: t('Common.Reports'),
      iconSvg: 'feature-reports',
      iconClassName: 'tn-i-report',
      component: Reports,
      navPosition: 'top',
      usePageTitle: true
    },
    {
      everyService: services.VEHMNT,
      path: '/vehiclemaintenance*',
      link: '/vehiclemaintenance/schedules',
      id: MENU_IDS.vehiclemaintenance,
      title: t('Common.VehicleMaintenance'),
      iconSvg: 'feature-vehicle-maintenance',
      iconClassName: 'tn-i-tools',
      component: VehicleMaintenanceRouter,
      navPosition: 'top',
      useBackButton: true,
      usePageTitle: true,
      featureHide: companyFeatures.HTF,
      isActive: (_, location) => {
        return location.pathname.includes('/vehiclemaintenance');
      }
    },
    {
      everyService: services.DMGT,
      path: '/drivermanagement*',
      link: '/drivermanagement',
      id: MENU_IDS.drivermanagement,
      title: t('Common.DriverManagement'),
      iconSvg: 'feature-driver-management',
      iconClassName: 'tn-i-driver',
      component: DriverManagementRouter,
      navPosition: 'top',
      useBackButton: true,
      usePageTitle: true
    },
    {
      everyService: services.EASYDOCS,
      path: '/easydocs*',
      link: '/easydocs',
      id: MENU_IDS.easydocs,
      title: t('Common.Easydocs'),
      iconSvg: 'feature-easydocs',
      icon: faNewspaper,
      component: EasydocsRouter,
      navPosition: 'top',
      useBackButton: true,
      usePageTitle: true
    },
    {
      everyService: services.JOURNEYPLANNER,
      path: '/journeyplanner*',
      link: '/journeyplanner',
      id: MENU_IDS.journeyplanner,
      title: t('Common.JourneyPlanner'),
      iconSvg: 'feature-journey-planner',
      iconClassName: 'tn-i-journey',
      component: JourneyPlannerRouter,
      navPosition: 'top',
      useBackButton: true,
      usePageTitle: true
    },
    {
      everyService: services.COMMON,
      path: '/events/:id',
      link: '/events',
      title: t('Common.EventDetail'),
      component: EventDetail,
      navPosition: false,
      showBackButton: true
    },
    {
      everyService: services.COMMON,
      path: '/events/:id/:type',
      link: '/events/:id/:type',
      title: t('Common.EventDetail'),
      component: NextGenEventDetail,
      navPosition: false,
      showBackButton: false
    },
    {
      everyService: services.COMMON,
      path: '/ng/reports/:id',
      link: '/reports/history/:id',
      title: t('Common.ReportResult'),
      component: Reports,
      navPosition: false,
      usePageTitle: true,
      useBackButton: true,
      redirection: true
    },
    {
      everyService: services.COMMON,
      path: '/runsheets/:id',
      link: '/runsheets/:id',
      title: t('Common.RunsheetEvent'),
      component: NextGenEventDetail,
      navPosition: false,
      useBackButton: true
    },
    {
      everyService: services.COMMON,
      path: '/notifications/:id',
      link: '/notifications/:id',
      title: t('Common.NotificationEvent'),
      component: NotificationEvent,
      navPosition: false
    },
    {
      everyService: services.TACHO,
      path: '/fatigue/tacho*',
      link: '/fatigue/tacho',
      exact: true,
      id: MENU_IDS.fatigueTacho,
      title: t('Common.Fatigue'),
      iconSvg: 'feature-fatigue',
      iconClassName: 'tn-i-steering-wheel',
      component: Fatigue,
      navPosition: 'top'
    },
    {
      everyCompanyService: services.ELD,
      everyEntity: entities.DRIVERPORTAL,
      featureFlag: FeatureFlag.driverPortal.flag,
      otherConditions: [() => user?.type?.code === 'DRIVER'],
      path: '/fatigue/eld/driverportal*',
      link: '/fatigue/eld/driverportal',
      id: MENU_IDS.fatigueDriverPortal,
      title: t('Common.DriverPortal'),
      iconSvg: 'feature-fatigue',
      iconClassName: 'tn-i-steering-wheel',
      component: ELD,
      navPosition: 'top',
      useBackButton: false,
      usePageTitle: true
    },
    {
      everyService: services.ELD,
      otherConditions: [() => user?.type?.code !== 'DRIVER'],
      path: '/fatigue/eld*',
      link: '/fatigue/eld',
      id: MENU_IDS.fatigueEld,
      title: t('Common.ELDFatigue'),
      iconSvg: 'feature-fatigue',
      iconClassName: 'tn-i-steering-wheel',
      component: ELD,
      navPosition: 'top',
      useBackButton: true,

      usePageTitle: true
    },
    {
      oneOfServices: [services.EWD, services.SWD, services.EDL],
      path: ['/fatigue(/All|/Working_Driving|/Resting)?', '/fatigue/sentinel*', '/fatigue/ewd*'],
      link: '/fatigue',
      exact: true,
      id: MENU_IDS.fatigue,
      title: t('Common.Fatigue'),
      iconSvg: 'feature-fatigue',
      iconClassName: 'tn-i-steering-wheel',
      component: FatigueAu,
      navPosition: 'top',
      fullPage: true,
      isActive: (_, location) => {
        if (
          location.pathname.indexOf('/fatigue/sentinel') === 0 ||
          location.pathname.indexOf('/fatigue/ewd') === 0 ||
          location.pathname === '/fatigue' ||
          location.pathname === '/fatigue/'
        ) {
          return true;
        }
        return false;
      },
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      everyCompanyService: services.INTEGRATION,
      path: '/integrations*',
      link: '/integrations/marketplace',
      id: MENU_IDS.integrations,
      title: t('Common.Integrations'),
      iconSvg: 'feature-integrations',
      component: IntegrationsRouter,
      navPosition: 'top',
      useBackButton: true,
      usePageTitle: true,
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      everyService: services.RUC,
      link: RUC_MANAGER_URL,
      id: MENU_IDS.rucManager,
      title: t('Entity.RUC Manager'),
      isExternalLink: true,
      iconSvg: 'feature-ruc-manager',
      iconClassName: 'tn-i-ruc',
      navPosition: 'top'
    },
    {
      everyService: services.ACM,
      link: acmURL,
      id: MENU_IDS.acm,
      title: t('Entity.ACM'),
      isExternalLink: true,
      iconSvg: 'feature-acm',
      iconClassName: 'tn-i-acm',
      navPosition: 'top'
    },
    {
      everyService: services.ONSITE_TRAFFIC,
      linkGenerator: onsiteTrafficUrl,
      id: MENU_IDS.onsiteTraffic,
      title: t('Entity.Onsite Traffic'),
      isExternalLink: true,
      iconSvg: 'feature-onsite-traffic',
      iconClassName: 'tn-i-traffic',
      navPosition: 'top'
    },
    {
      everyService: services.NETRADYNE,
      everyEntity: entities.VIDEO,
      id: MENU_IDS.driveriCamera,
      linkGenerator: netradyneUrl,
      title: t('Entity.Netradyne Portal'),
      isExternalLink: true,
      iconSvg: 'feature-driveri-camera',
      iconClassName: 'tn-i-netradyne',
      navPosition: 'top'
    },
    {
      everyService: services.EVREADY,
      oneOfEntities: [entities.KONETIK_VIEW, entities.KONETIK_UPDATE],
      link: '/mixed-energy',
      path: '/mixed-energy',
      id: MENU_IDS.mixedEnergy,
      title: t('MixedEnergy.MixedEnergy'),
      iconSvg: 'feature-mixed-energy',
      iconClassName: 'tn-i-mixed-energy',
      component: MixedEnergy,
      navPosition: 'top'
    },
    {
      everyService: services.COMMON,
      featureFlag: FeatureFlag.storeMenuItem.flag,
      link: STORE_URL,
      id: MENU_IDS.store,
      title: t('Common.Store'),
      iconSvg: 'feature-store',
      iconClassName: 'tn-i-shop',
      navPosition: 'bottom',
      isExternalLink: true,
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      everyService: services.COMMON,
      path: ['/settings*', '/admin/ng/forms*'],
      link: accessibleAdminPage?.length !== 0 ? accessibleAdminPage[0].link : '/settings/users',
      id: MENU_IDS.settings,
      title: t('Common.Settings'),
      iconSvg: 'feature-settings',
      iconClassName: 'tn-i-multi-cog',
      component: AppSettings,
      navPosition: 'bottom',
      fullPage: true
    },
    {
      everyService: [services.COMMON, services.CAMERA],
      path: '/view_events/events/:id/:type',
      link: '/view_events/events/:id/:type',
      title: t('Tracking.ActionsMenu.ViewEvent'),
      component: ViewCameraEvents,
      navPosition: false,
      showBackButton: true
    },
    {
      everyService: [services.COMMON],
      link: vltURL,
      id: MENU_IDS.vlt,
      title: t('VLT'),
      isExternalLink: true,
      iconSvg: 'feature-vlt',
      iconClassName: 'tn-i-vlt',
      navPosition: 'top',
      otherConditions: [() => canVltRole && vltURL]
    },
    {
      otherConditions: [() => user?.siteAdmin],
      path: ['/supportTools*'],
      link: '/supportTools/ELD/Summary',
      id: MENU_IDS.supportTools,
      title: t('SupportToolsELD.MainMenuTitle'),
      iconSvg: 'logo-collapsed-nofill',
      iconClassName: 'tn-i-multi-cog',
      component: SupportTools,
      navPosition: 'bottom',
      fullPage: true
    }
  ];
};

export const administrationPages = (t, canAccessNonCameraFeatures) => {
  return [
    {
      path: '/settings/users',
      link: '/settings/users',
      id: MENU_IDS.users,
      title: t('Common.Users'),
      component: UsersRouter,
      everyEntity: [entities.USER],
      beyondRoles: [GlobalRoles.IQCameraUser]
    },
    {
      path: '/settings/roles',
      link: '/settings/roles',
      id: MENU_IDS.roles,
      title: t('Common.Roles'),
      component: RolesRouter,
      everyEntity: [entities.ROLE],
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      path: '/settings/fleets',
      link: '/settings/fleets',
      id: MENU_IDS.fleets,
      title: t('Common.Fleets'),
      component: FleetsRouter,
      everyEntity: [entities.FLEET],
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      path: '/settings/vehicles',
      link: '/settings/vehicles',
      id: MENU_IDS.vehicles,
      title: t('Common.Vehicles'),
      component: VehiclesRouter,
      everyEntity: [entities.VEHICLE, entities.FLEET]
    },
    {
      path: '/settings/devices',
      link: '/settings/devices',
      id: MENU_IDS.devices,
      title: t('Common.Devices'),
      component: DevicesRouter,
      everyEntity: [entities.DEVICE, entities.FLEET],
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      path: '/settings/locations',
      link: '/settings/locations',
      id: MENU_IDS.locations,
      title: t('Common.Locations'),
      component: LocationsRouter,
      everyEntity: [entities.LOCATION]
    },
    {
      path: '/settings/geofences',
      link: '/settings/geofences',
      id: MENU_IDS.geofences,
      title: t('Common.Geofences'),
      component: GeofencesRouter,
      everyEntity: [entities.GEOFENCE],
      everyService: [services.GEOFENCE],
      featureHide: companyFeatures.HTF
    },
    {
      path: '/settings/wifi',
      link: '/settings/wifi',
      id: MENU_IDS.wifi,
      title: t('WiFi.WiFi'),
      component: WifiRouter,
      everyEntity: [entities.WIFI]
    },
    {
      path: '/settings/subcompanies',
      link: '/settings/subcompanies',
      id: MENU_IDS.subCompany,
      title: t('SubCompanies.SubCompanies'),
      component: SubCompaniesRouter,
      everyEntity: [entities.SUBCOMPANY],
      featureFlag: FeatureFlag.subCompanyManagement.flag
    }
  ];
};

export const configurationPages = (t, user, canCompanyConfig, canAccessNonCameraFeatures) => {
  return [
    {
      path: '/settings/alerts',
      link: '/settings/alerts',
      id: MENU_IDS.companyAlerts,
      title: t('Common.CompanyAlerts'),
      component: CompanyAlertsRouter,
      isAdminFeature: true,
      everyEntity: [entities.COMPANYALERT],
      oneOfServices: [
        services.CAMERA,
        services.DMGT,
        services.DURESS,
        services.VPM,
        services.FORM,
        services.GEOFENCE,
        services.PRETRIP,
        services.ROUTECOMPLIANCE,
        services.SWD,
        services.SMARTJOBS,
        services.SPEED,
        services.TPMS,
        services.VEHMNT,
        services.COMMON,
        services.IAP
      ]
    },
    {
      path: '/settings/company_config',
      link: '/settings/company_config',
      id: MENU_IDS.companyConfig,
      title: t('CompanyConfig.Title'),
      component: CompanyConfigRouter,
      isAdminFeature: true,
      otherConditions: [() => canCompanyConfig]
    },
    {
      path: '/settings/driverMgtTypes',
      link: '/settings/driverMgtTypes',
      id: MENU_IDS.driverMgtTypes,
      title: t('Common.DriverMgtTypes'),
      component: DriverMgtTypes.DriverMgtTypesRouter,
      everyEntity: [entities.DRIVERMANAGEMENTTYPE],
      everyService: [services.DMGT]
    },
    {
      path: ['/settings/forms', '/admin/ng/forms'],
      link: '/settings/forms',
      id: MENU_IDS.forms,
      title: t('Common.Forms'),
      component: FormsRouter,
      everyEntity: [entities.FORM],
      everyService: [services.FORM],
      everyCompanyService: services.FORM
    },
    {
      path: '/settings/inspectionChecklist',
      link: '/settings/inspectionChecklist',
      id: MENU_IDS.inspectionChecklist,
      title: t('Common.InspectionChecklist'),
      component: InspectionChecklistRouter,
      everyEntity: [entities.PRETRIPCHECKLIST],
      everyService: [services.PRETRIP],
      featureFlag: FeatureFlag.dvir_inspection.flag
    },
    {
      path: '/settings/vehicleMntTypes',
      link: '/settings/vehicleMntTypes',
      id: MENU_IDS.vehicleMntTypes,
      title: t('Common.VehicleMntTypes'),
      component: VehicleMntTypesRouter,
      everyEntity: [entities.VEHICLEMAINTENANCETYPE],
      everyService: [services.VEHMNT],
      featureHide: companyFeatures.HTF
    },
    {
      path: '/settings/vehicleTypes',
      link: '/settings/vehicleTypes',
      id: MENU_IDS.vehicleTypes,
      title: t('Common.VehicleTypes'),
      component: VehicleTypesRouter,
      everyEntity: [entities.VEHICLETYPE],
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      path: '/settings/smartJobs/codes',
      link: '/settings/smartJobs/codes',
      id: MENU_IDS.smartJobsCodes,
      title: t('Common.SmartJobsCodes'),
      component: SmartJobsCodes.SmartJobsCodesRouter,
      everyEntity: [entities.SMARTJOBSCODE]
    },
    {
      path: '/settings/massmanagement',
      link: '/settings/massmanagement',
      id: MENU_IDS.massManagement,
      title: t('Common.MassManagement'),
      component: MassManagementRouter,
      everyEntity: [entities.VEHICLE],
      everyService: [services.MASS],
      otherConditions: [() => canAccessNonCameraFeatures]
    },
    {
      path: '/settings/enrolments',
      link: '/settings/enrolments',
      id: MENU_IDS.tcaEnrolments,
      title: t('Common.TCAEnrolments'),
      component: TCAEnrolments.TCAEnrolmentsRouter,
      everyEntity: [entities.USER],
      featureFlag: FeatureFlag.TCAEnrolments.flag,
      otherConditions: [() => user.siteAdmin]
    }
  ];
};

export const userPages = (t, canAccessNonCameraFeatures) => {
  return [
    {
      path: '/settings/user/alerts',
      link: '/settings/user/alerts',
      id: MENU_IDS.alerts,
      title: t('Common.Alerts'),
      exact: false,
      component: UserAlertsRouter,
      everyService: [services.COMMON]
    },
    // {
    //   path: '/settings/user/automation',
    //   link: '/settings/user/automation',
    //   id: MENU_IDS.automation,
    //   title: t('Common.Automation'),
    //   exact: false,
    //   component: Automation,
    //   everyService: [services.COMMON]
    // },
    {
      path: '/settings/user/preferences',
      link: '/settings/user/preferences',
      id: MENU_IDS.preferences,
      title: t('Common.Preferences'),
      exact: true,
      component: UserPreferences,
      everyService: [services.COMMON],
      otherConditions: [() => canAccessNonCameraFeatures]
    }
  ];
};

export const supportToolPagesFeatures = (t, user, hasSiteAdminOrResellerRole) => {
  return [
    {
      otherConditions: [() => user?.siteAdmin],
      path: '/supportTools/ELD',
      link: '/supportTools/ELD',
      id: MENU_IDS.supportToolsELD,
      title: t('SupportToolsELD.FeatureTitleELD'),
      component: ELDRouter
    },
    {
      featureFlag: FeatureFlag.installationAuditLog.flag,
      everyService: [services.NPI],
      otherConditions: [() => hasSiteAdminOrResellerRole],
      path: '/supportTools/installationAuditLog',
      link: '/supportTools/installationAuditLog',
      id: MENU_IDS.supportToolsInstallationAuditLog,
      title: t('SupportToolsELD.InstallationAuditLogTitle'),
      component: InstallationAuditLogsRouter
    }
  ];
};
