import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompanies, useIsFetchingCompany } from 'features/company/companySlice';
import { Button, Col, Form, Radio, Modal, Row, Select, Table, Checkbox, Space } from 'antd';
import styles from './DeviceTransferModal.module.scss';
import { useGetCompanyAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DeviceTransferModal = ({
  device,
  vehicle,
  isOpen,
  onClose,
  onComplete,
  targetCompanyId
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const companies = useCompanies();
  const isFetchingCompany = useIsFetchingCompany();
  const [deviceTransferForm] = Form.useForm();
  const [assignAgreement, setAssignAgreement] = useState(false);
  const selectedCompany = Form.useWatch('targetCompany', deviceTransferForm);
  const selectedCompanyName = companies?.find(i => i.id == selectedCompany)?.name;

  const { data: agreementData, isFetching: isFetchingAgreement } = useGetCompanyAgreementsQuery(
    {
      id: selectedCompany
    },
    { skip: selectedCompany === undefined }
  );

  useEffect(() => {
    if (isOpen && device && targetCompanyId) {
      deviceTransferForm.setFieldsValue({
        currentCompany: device.companyId,
        deviceOnly: !vehicle || Object.keys(vehicle).length === 0,
        targetCompany: parseInt(targetCompanyId)
      });
    } else if (isOpen) {
      deviceTransferForm.resetFields();
    }
  }, [device, targetCompanyId, isOpen, vehicle]);

  const footer = () => {
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            deviceTransferForm.submit();
          }}
          htmlType="submit"
          id={BUTTON_IDS.confirmTransferCompanyBtn}
        >
          {t('Common.Save')}
        </Button>
        <Button onClick={onClose}>{t('Common.CancelButton')}</Button>
      </>
    );
  };

  const columns = [
    {
      title: t('Devices.TransferModal.WorkOrder'),
      dataIndex: 'workOrder',
      key: 'name',
      width: '25%'
    },
    {
      title: t('Devices.TransferModal.ContractEndDate'),
      render: (e, r) => {
        return (
          <span>{format(new Date(r.contractEndAt), localization.formats.time.formats.dby)}</span>
        );
      },
      width: '20%'
    },
    {
      title: t('Devices.TransferModal.Description'),
      dataIndex: 'description',
      key: 'description',
      width: '30%'
    },
    {
      title: t('Devices.TransferModal.SubscriptionPack'),
      dataIndex: 'description',
      key: 'description',
      render: (e, r) => {
        return <span>{r.subscriptionPack.name}</span>;
      },
      width: '25%'
    }
  ];

  return (
    <Modal
      title={
        vehicle && Object.keys(vehicle).length !== 0
          ? t('Devices.TransferModal.Title')
          : t('Devices.TransferModal.StandaloneTitle')
      }
      open={isOpen}
      centered={true}
      width={1000}
      onCancel={onClose}
      destroyOnClose={true}
      footer={footer()}
    >
      <Form
        layout="vertical"
        form={deviceTransferForm}
        name="deviceTransferForm"
        onFinish={() => {
          const returnObj = {
            deviceOnly: deviceTransferForm.getFieldValue('deviceOnly'),
            companyId: deviceTransferForm.getFieldValue('targetCompany'),
            agreements: deviceTransferForm.getFieldValue('agreements')
          };
          onComplete(returnObj);
        }}
      >
        <Row gutter={[64, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="currentCompany"
              labelAlign={'left'}
              label={t(`Devices.TransferModal.CurrentCompany`)}
              colon={false}
            >
              <Select
                name="currentCompanySelect"
                disabled={true}
                options={(companies || []).map(e => {
                  return { value: e.id, label: e.name };
                })}
                loading={isFetchingCompany || isFetchingAgreement}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="targetCompany"
              labelAlign={'left'}
              label={t(`Devices.TransferModal.DestinationCompany`)}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('Devices.TransferModal.DestinationCompanyRequired')
                }
              ]}
            >
              <Select
                name="targetCompanySelect"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                options={(companies || [])
                  .filter(i => i.id !== device.companyId)
                  .map(e => {
                    return { value: e.id, label: e.name };
                  })}
                loading={isFetchingCompany || isFetchingAgreement}
              />
            </Form.Item>
          </Col>
        </Row>
        {vehicle && Object.keys(vehicle).length !== 0 && (
          <Row gutter={[64, 0]}>
            <Col xs={24}>
              <Form.Item
                name="deviceOnly"
                labelAlign={'left'}
                label={t(`Devices.TransferModal.TansferOption`)}
                colon={false}
                className={styles.settingDiv}
                initialValue={false}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={false}>
                      {t(`Devices.TransferModal.TansferBothVehicleAndDevice`, {
                        company: selectedCompanyName
                      })}
                    </Radio>
                    <Radio value={true}>
                      {t(`Devices.TransferModal.UnpairAndTransfer`, {
                        company: selectedCompanyName
                      })}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[64, 0]}>
          <Col span={24}>
            <Row className={styles.settingDiv}>
              <Col span={24}>
                <Checkbox
                  onChange={(e, a) => {
                    setAssignAgreement(e.target.checked);
                  }}
                >
                  {t(`Devices.TransferModal.AssignAgreement`)}
                </Checkbox>
              </Col>
              {assignAgreement && (
                <Col span={24} className={styles.agreementDiv}>
                  <Form.Item
                    name="agreements"
                    labelAlign={'left'}
                    colon={false}
                    shouldUpdate
                    rules={[
                      {
                        required: true,
                        message: t('Devices.TransferModal.AgreementRequired')
                      }
                    ]}
                  >
                    <Table
                      bordered
                      loading={isFetchingCompany || isFetchingAgreement}
                      columns={columns}
                      rowKey={'id'}
                      rowSelection={{
                        ...{
                          onChange: (selectedRowKeys, selectedRows) => {
                            deviceTransferForm.setFieldValue(
                              'agreements',
                              selectedRows.map(i => i.id)
                            );
                          }
                        }
                      }}
                      dataSource={agreementData?.map(i => i.agreements)?.flat()}
                      pagination={false}
                      scroll={{ y: 300 }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
