import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { addBreadcrumbs, setPageTitle } from 'features/page/pageSlice';
import { Can, FeatureFlag, GlobalRoles, services, useCanFeatureFlag } from 'features/permissions';
import { ConfigCard } from './Card';
import { PATHS, UNIQUE_IDS } from './utils/constants';

import styles from './CompanyConfig.module.scss';

export const DeviceConfig = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const getForceShowCondition = c => {
    return c.id === UNIQUE_IDS.ELD && history?.location?.state?.forceShow;
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.DeviceConfigurations.Title')));
    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, t]);

  const configList = [
    {
      title: 'CompanyConfig.DeviceConfigurations.GPIOConfig.Title',
      shortDescription: 'CompanyConfig.DeviceConfigurations.GPIOConfig.Description',
      permissions: {
        oneOfRoles: [GlobalRoles.SiteAdmin, GlobalRoles.Reseller],
        oneOfCompanyServices: [services.GPIO]
      },
      navigateTo: PATHS.GPIO_LINKS,
      id: UNIQUE_IDS.GPIO_CONFIGURATIONS
    },
    {
      title: 'CompanyConfig.DeviceConfigurations.LogOnEvents.Title',
      shortDescription: 'CompanyConfig.DeviceConfigurations.LogOnEvents.Description',
      permissions: {
        featureFlag: FeatureFlag.logOnEventsConfiguration.flag
      },
      navigateTo: PATHS.LOG_ON_EVENTS,
      id: UNIQUE_IDS.LOG_ON_EVENTS_CONFIGURATIONS
    },
    {
      title: 'Driver ID',
      shortDescription: 'Driver ID Configurations',
      permissions: {
        featureFlag: FeatureFlag.driverIdPage.flag,
        oneOfRoles: [GlobalRoles.SiteAdmin, GlobalRoles.Reseller],
        oneOfCompanyServices: [services.DRIVERPIN]
      },
      navigateTo: PATHS.DRIVER_ID,
      id: UNIQUE_IDS.DRIVER_ID
    },
    {
      title: 'CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Title',
      shortDescription: 'CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Description',
      permissions: {
        featureFlag: FeatureFlag.deviceConfig_speedAssist.flag,
        oneOfRoles: [GlobalRoles.SiteAdmin, GlobalRoles.Reseller, GlobalRoles.Admin],
        oneOfCompanyServices: [services.SPEEDASSIST]
      },
      navigateTo: PATHS.SPEEDASSIST_LINKS,
      id: UNIQUE_IDS.SPEEDASSIST_CONFIGURATIONS
    }
  ];

  return (
    <div className={styles.companyConfig}>
      {configList.map((c, idx) => {
        return (
          <Can key={idx} {...c.permissions}>
            <ConfigCard
              title={t(c.title)}
              shortDescription={t(c.shortDescription)}
              modal={c.modal}
              modalDetails={c.modalDetails}
              navigateTo={c.navigateTo}
              id={c.id}
              forceShow={getForceShowCondition(c)}
            />
          </Can>
        );
      })}
    </div>
  );
};
