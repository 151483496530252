import { Modal, Progress } from 'antd';
//import styles from './InstallationLog.module.scss';
import './InstallationLog.module.scss'; // Import your SCSS file with global styles

//@Author Durga Prasad Kolli
const ProgressModal = ({ open, progress, onClose, title, t }) => {
  const footer = null;
  const { completed, total } = progress;

  // Calculate the percentage, ensuring it defaults to 0 if `total` is 0
  const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;

  return (
    <Modal
      title={<span style={modalTitleStyle}>{title}</span>}
      open={open}
      footer={null}
      onCancel={onClose}
      centered
      closable={false}
      maskClosable={false}
      //className={styles.mycomponent}
    >
      <div style={{ textAlign: 'center' }}>
        <div style={{ backgroundColor: '#f0f0f0', padding: '15px', borderRadius: '4px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '-5px'
            }}
          >
            <span>
              {completed}/{total} {t('Common.title', { title: 'photos downloaded' })}
            </span>
            <span>{percentage}%</span>
          </div>
          <Progress percent={percentage} strokeColor="#52c41a" showInfo={false} size="medium" />
        </div>
      </div>
    </Modal>
  );
};

export default ProgressModal;

const modalTitleStyle = {
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '5px',
  padding: '15px'
};
