import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { Layout } from 'antd';
import { sortBy } from 'lodash';
import { format } from 'utils/dates';
import { prepareDataForMultiselect } from 'utils/filters';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import {
  fetchInspectionSummary,
  useInspectionSummary,
  useIsInspectionsFetching
} from 'features/inspectionChecklist/inspectionChecklistSlice';
import { InspectionSummaryTable } from './InspectionSummaryTable';
import { InspectionSummaryToolbar } from './InspectionSummaryToolbar';
import { ExportToExcel } from './ExportToExcel';
import { getDaysList, getStatusList } from '../Inspections/utils/constants';
import { DateRangePicker } from 'components/ant';
import styles from '../VehicleMaintenanceSchedules.module.scss';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const InspectionSummary = () => {
  const localization = useLocalization();
  const dateFormat = localization.formats.time.formats.dby;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const daysList = getDaysList(t);
  const statusList = getStatusList(t);
  const defaultDates = [dayjs().startOf('day'), dayjs().endOf('day')];
  const [selectedDates, setSelectedDates] = useState(defaultDates);
  const [dateFrom, setDateFrom] = useState(selectedDates[0].format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(selectedDates[1].format('YYYY-MM-DD'));
  const [datePicker, setDatePicker] = useState({
    isOpen: false,
    hasBeenOpened: false,
    haveDatesBeenChanged: false
  });
  const [searchText, setSearchText] = useState(null);
  const [fleetsFilter, setFleetsFilter] = useState([]);
  const [driversFilter, setDriversFilter] = useState([]);
  const [vehiclesFilter, setVehiclesFilter] = useState([]);
  const [daysFilter, setDaysFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);

  const FilterIds = {
    AllCompanies: 0,
    AllFleets: 0,
    AllDrivers: 0,
    AllVehicles: 0,
    AllDays: 0,
    AllStatus: 0,
    NoCompany: -1,
    NoFleet: -1,
    NoDriver: -1,
    NoVehicle: -1,
    NoDay: -1,
    NoInspections: -1
  };

  const inspectionSummary = useInspectionSummary(dateFrom, dateTo);
  const isFetching = useIsInspectionsFetching();

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(fetchInspectionSummary(dateFrom, dateTo));
  }, []);

  useEffect(() => {
    let fleetsList = [];
    let driversList = [];
    let vehiclesList = [];

    if (inspectionSummary) {
      inspectionSummary?.forEach(inspection => {
        //fleets
        if (inspection?.fleets) {
          inspection.fleets.forEach(fleet => {
            if (fleet && !fleetsList.find(f => f.id === fleet.id)) {
              fleetsList.push({ id: fleet.id, name: fleet.name });
              fleetsList.push();
            }
          });
        }
        //drivers
        if (inspection?.drivers) {
          inspection.drivers.forEach((driver, index) => {
            if (driver && !driversList.find(f => f.id === driver.id)) {
              driversList.push({ id: driver.id, name: driver?.firstName + ' ' + driver?.lastName });
              driversList.push();
            }
          });
        }
        //vehicle
        if (inspection?.vehicle) {
          const vehicle = inspection.vehicle;
          if (vehicle && !vehiclesList.find(v => v.id === vehicle.id)) {
            vehiclesList.push({ id: vehicle.id, name: vehicle.name });
          }
        }
      });

      // sort all the lists by name
      fleetsList = sortBy(fleetsList, [item => item.name.toLowerCase()]);
      driversList = sortBy(driversList, [item => item.name.toLowerCase()]);
      vehiclesList = sortBy(vehiclesList, [item => item.name.toLowerCase()]);

      // Adding an option for no fleet/driver/vehicle/day
      fleetsList.push({ id: FilterIds.NoFleet, name: t('Common.NoFleet') });
      driversList.push({ id: FilterIds.NoDriver, name: t('Common.NoDriver') });
      vehiclesList.push({ id: FilterIds.NoVehicle, name: t('Common.NoVehicle') });
      const noDayExists = daysList.some(day => day.id === FilterIds.NoDay);
      !noDayExists && daysList.push({ id: FilterIds.NoDay, name: t('Common.NoDay') });
      const noInspectionsExists = statusList.some(ins => ins.id === FilterIds.NoInspections);
      !noInspectionsExists &&
        statusList.push({
          id: FilterIds.NoInspections,
          name: (
            <>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: '#64748b', marginRight: '5px' }}
              />
              {t('InspectionSummary.NoInspections')}
            </>
          )
        });

      setFleetsFilter(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
      setDriversFilter(prepareDataForMultiselect(driversList, t('Common.AllDrivers'), null));
      setVehiclesFilter(prepareDataForMultiselect(vehiclesList, t('Common.AllVehicles'), null));
      setDaysFilter(prepareDataForMultiselect(daysList, t('Common.AllDays'), null));
      setStatusFilter(prepareDataForMultiselect(statusList, t('Common.AllStatus'), null));
    }
  }, [inspectionSummary]);

  useEffect(() => {
    if (inspectionSummary) {
      let newfilteredInspections = [];

      inspectionSummary.forEach(row => {
        let matchSearch = true;
        // filtering functionality
        if (matchSearch && searchText && searchText.trim()) {
          const searchTextLowerCase = searchText.trim().toLowerCase();
          matchSearch =
            row.vehicle?.name?.toLowerCase().includes(searchTextLowerCase) ||
            row.fleets?.some(f => f.name.toLowerCase().indexOf(searchTextLowerCase) >= 0) ||
            row.inspections?.some(
              i => i.checklistName.toLowerCase().indexOf(searchTextLowerCase) >= 0
            ) ||
            row.inspections?.some(
              i => i.location.toLowerCase().indexOf(searchTextLowerCase) >= 0
            ) ||
            row.inspections?.some(
              i =>
                format(new Date(i.inspectionDate), 'hh:mm A')
                  .toLowerCase()
                  .indexOf(searchTextLowerCase) >= 0
            ) ||
            row.drivers?.some(
              d =>
                (d?.firstName?.toLowerCase() + ' ' + d?.lastName?.toLowerCase()).indexOf(
                  searchTextLowerCase
                ) >= 0
            ) ||
            row.dayOfWeek?.toLowerCase().includes(searchTextLowerCase) ||
            localization
              ?.convertDistance(row.distance)
              ?.toString()
              ?.includes(searchTextLowerCase) ||
            row.engineHours
              .toFixed(1)
              ?.toString()
              ?.includes(searchTextLowerCase) ||
            row.total?.toString()?.includes(searchTextLowerCase) ||
            row.failed?.toString()?.includes(searchTextLowerCase) ||
            format(new Date(row.date), localization.formats.time.formats.dby_imsp)
              .toLowerCase()
              .includes(searchTextLowerCase);
        }

        if (
          matchSearch &&
          fleetsFilter &&
          !fleetsFilter.find(f => f.id === FilterIds.AllFleets)?.checked
        ) {
          const hasFleet = row.fleets && row.fleets.length > 0;
          matchSearch = fleetsFilter.some(
            f =>
              f.checked &&
              ((row.fleets && row.fleets.some(fleet => f.id === fleet.id)) ||
                (f.id === FilterIds.NoFleet && !hasFleet))
          );
        }

        if (
          matchSearch &&
          vehiclesFilter &&
          !vehiclesFilter.find(f => f.id === FilterIds.AllVehicles)?.checked
        ) {
          const hasVehicle = row.vehicle && row.vehicle.id;
          matchSearch = vehiclesFilter.some(
            f =>
              f.checked &&
              ((row.vehicle && f.id === row.vehicle.id) ||
                (f.id === FilterIds.NoVehicle && !hasVehicle))
          );
        }

        if (
          matchSearch &&
          driversFilter &&
          !driversFilter.find(f => f.id === FilterIds.AllDrivers)?.checked
        ) {
          const hasDriver = row.drivers && row.drivers.length > 0;
          matchSearch = driversFilter.some(
            f =>
              f.checked &&
              ((row.drivers && row.drivers.some(driver => f.id === driver.id)) ||
                (f.id === FilterIds.NoDriver && !hasDriver))
          );
        }

        if (
          matchSearch &&
          daysFilter &&
          !daysFilter.find(f => f.id === FilterIds.AllDays)?.checked
        ) {
          const hasDay = row.dayOfWeek;
          matchSearch = daysFilter.some(
            f =>
              f.checked &&
              ((row.dayOfWeek && f.id === row.dayOfWeek) || (f.id === FilterIds.NoDay && !hasDay))
          );
        }

        if (
          matchSearch &&
          statusFilter &&
          !statusFilter.find(f => f.id === FilterIds.AllStatus)?.checked
        ) {
          const hasInspections = row.inspections && row.inspections.length > 0;
          matchSearch = statusFilter.some(
            f =>
              f.checked &&
              ((hasInspections && row.inspections.some(inspection => f.id === inspection.status)) ||
                (f.id === FilterIds.NoInspections && !hasInspections))
          );
        }

        if (matchSearch) {
          newfilteredInspections.push(row);
        }
      });

      setFilteredInspections(newfilteredInspections);
    }
  }, [
    inspectionSummary,
    searchText,
    fleetsFilter,
    vehiclesFilter,
    driversFilter,
    daysFilter,
    statusFilter
  ]);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleFleetsFilterChange = useCallback(fleetsFilter => {
    setFleetsFilter(fleetsFilter);
  }, []);

  const handleVehiclesFilterChange = useCallback(vehiclesFilter => {
    setVehiclesFilter(vehiclesFilter);
  }, []);

  const handleDriversFilterChange = useCallback(driversFilter => {
    setDriversFilter(driversFilter);
  }, []);

  const handleDaysFilterChange = useCallback(daysFilter => {
    setDaysFilter(daysFilter);
  }, []);

  const handleStatusFilterChange = useCallback(statusFilter => {
    setStatusFilter(statusFilter);
  }, []);

  //date picker functionality, summary fetch
  useEffect(() => {
    const { isOpen, hasBeenOpened, haveDatesBeenChanged } = datePicker;
    if (!isOpen && hasBeenOpened && haveDatesBeenChanged) {
      dispatch(fetchInspectionSummary(dateFrom, dateTo));
      setDatePicker(prev => {
        return { ...prev, haveDatesBeenChanged: false };
      });
    }
  }, [datePicker, dispatch]);

  const handleDateRangeChange = newRange => {
    setSelectedDates(newRange);
    const formattedDates = newRange.map(date => date.format('YYYY-MM-DD'));
    setDateFrom(formattedDates[0]);
    setDateTo(formattedDates[1]);
    setDatePicker(prev => {
      return { ...prev, haveDatesBeenChanged: true };
    });
  };

  const handleDateRangeClose = isOpen => {
    setDatePicker(prev => {
      return { ...prev, isOpen, hasBeenOpened: true };
    });
  };

  return (
    <>
      <div className={styles.datePickerStyle}>
        <DateRangePicker
          size="large"
          maxDayRange={7}
          availableDatesRange={[0, moment().endOf('day')]}
          format={localization.formats.time.formats.dby.toUpperCase()}
          defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
          showPast14Days={false}
          showLastMonth={false}
          onDateRangeChanged={dates => {
            handleDateRangeChange(dates);
          }}
          onOpenChange={handleDateRangeClose}
        />
        <ExportToExcel
          data={filteredInspections}
          localization={localization}
          dateRange={[dayjs(dateFrom).format(dateFormat), dayjs(dateTo).format(dateFormat)]}
        />
      </div>
      <div>
        <InspectionSummaryToolbar
          filteredInspectionsCount={filteredInspections?.length}
          searchText={searchText}
          fleetsFilter={fleetsFilter}
          driversFilter={driversFilter}
          vehiclesFilter={vehiclesFilter}
          daysFilter={daysFilter}
          statusFilter={statusFilter}
          onSearchChange={handleSearchChange}
          onFleetsFilterChange={handleFleetsFilterChange}
          onVehiclesFilterChange={handleVehiclesFilterChange}
          onDriversFilterChange={handleDriversFilterChange}
          onDaysFilterChange={handleDaysFilterChange}
          onStatusFilterChange={handleStatusFilterChange}
          isFetching={isFetching}
        />
      </div>
      <Layout>
        <InspectionSummaryTable inspectionData={filteredInspections} isFetching={isFetching} />
      </Layout>
    </>
  );
};
