import React, { useState, useEffect, useRef } from 'react';
import {
  stringCellRenderer_ANT,
  VehicleCellRenderer_ANT,
  dateCellRenderer_ANT,
  decimalCellRenderer
} from '../utils/CellRenderers';
import { Table, Tooltip, Modal } from 'antd';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SortUtil } from 'components/tables/SortUtil';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';

import { useLocalization } from 'features/localization/localizationSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapModal } from 'components/modals/map-modal/MapModal';
import {
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'utils/dates';
import { truncate } from 'utils/strings';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const rowAction = (id, items, action) => {
  const newCheckedValues = (items || []).includes(id)
    ? items.filter(item => item !== id)
    : [...(items || []), id];
  action(newCheckedValues);
};

export const InspectionSummaryTable = ({
  inspectionData,
  setTableRef,
  checkedItems,
  setCheckedItems,
  isFetching
}) => {
  const sortedInspections = inspectionData?.slice().sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA; // For descending order (newest to oldest)
  });

  const { t } = useTranslation();
  const localization = useLocalization();
  const { tableRef, vtSettings } = useTableResize();
  const dispatch = useDispatch();
  const [mapModalDetails, setMapModalDetails] = useState(undefined);
  const [inspectionModalDetails, setInspectionModalDetails] = useState(undefined);

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  const handleMapModal = inspection => {
    setInspectionModalDetails(undefined);
    setMapModalDetails({
      location: inspection?.location,
      coordinates: {
        Lat: inspection?.gps?.Lat,
        Lng: inspection?.gps?.Lng
      }
    });
  };

  const handleInspectionModal = inspections => {
    setInspectionModalDetails({ inspections: inspections });
  };

  const allColumns = [
    {
      title: t('InspectionSummary.Date'),
      key: 'date',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'date',
      width: 100,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby,
          date: rowData.date
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'date'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.Vehicle'),
      key: 'vehicle',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'vehicle.name',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return VehicleCellRenderer_ANT({ rowData: rowData });
      },
      sorter: (a, b) => {
        const nameA = (a.vehicle?.name || '').toLowerCase();
        const nameB = (b.vehicle?.name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.Fleet'),
      key: 'fleets',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'fleets',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: rowData => {
        const fleets = rowData?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(fleets || '');
      },
      defaultSortColumn: false,
      sorter: (a, b) => {
        const nameA = (a.fleets || [])
          .map(f => f.name)
          .join(', ')
          .toLowerCase();
        const nameB = (b.fleets || [])
          .map(f => f.name)
          .join(', ')
          .toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.Driver'),
      key: 'driver',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'drivers',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: rowData => {
        const drivers = rowData?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(drivers || '');
      },
      defaultSortColumn: false,
      sorter: (a, b) => {
        const nameA = (a.drivers || [])
          .map(d => d.name)
          .join(', ')
          .toLowerCase();
        const nameB = (b.drivers || [])
          .map(d => d.name)
          .join(', ')
          .toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.Day'),
      key: 'dayOfWeek',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'dayOfWeek',
      width: 100,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData) => t('Weekdays.' + rowData.dayOfWeek),
      sorter: (a, b) => {
        const daysOfWeekOrder = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 7
        };
        const nameA = (a.dayOfWeek || '').toLowerCase();
        const nameB = (b.dayOfWeek || '').toLowerCase();
        const orderA = daysOfWeekOrder[nameA];
        const orderB = daysOfWeekOrder[nameB];

        if (orderA === undefined) return 1;
        if (orderB === undefined) return -1;

        return orderA - orderB;
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.DistanceDriven'),
      key: 'distance',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'distance',
      width: 140,
      render: (value, rowData, rowIndex) => {
        return decimalCellRenderer({ value, localization, distanceMode: true });
      },
      sorter: (a, b) => a.distance - b.distance,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.EngineHours'),
      key: 'engineHours',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'engineHours',
      width: 130,
      render: (value, rowData, rowIndex) => {
        return decimalCellRenderer({ value });
      },
      sorter: (a, b) => a.engineHours - b.engineHours,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.InspectionsPerformed'),
      key: 'total',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'total',
      width: 180,
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.InspectionsFailed'),
      key: 'failed',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'failed',
      width: 160,
      sorter: (a, b) => a.failed - b.failed,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('InspectionSummary.Inspections'),
      key: 'inspections',
      isConfigurable: false,
      dataIndex: 'inspections',
      width: 450,
      render: (value, rowData, rowIndex) => {
        let inspections = [];
        try {
          inspections = rowData?.inspections || [];
        } catch (e) {
          console.error(e);
        }
        if (inspections.length === 0) {
          return (
            <>
              <div>
                <FontAwesomeIcon icon={faTimesCircle} className={styles.noInspectionIconClass} />
                <span className={styles.noInspectionClass}>
                  {t('InspectionSummary.NoInspections')}
                </span>
              </div>
            </>
          );
        }
        return (
          <>
            <div>
              {inspections.slice(0, 4).map(inspection => {
                return (
                  <>
                    <div>
                      <Tooltip title={inspection?.checklistName}>
                        <FontAwesomeIcon
                          icon={
                            inspection?.status === 'FAIL' ? faExclamationTriangle : faCheckCircle
                          }
                          className={
                            inspection?.status === 'FAIL'
                              ? styles.failedIconClass
                              : styles.passedIconClass
                          }
                        />
                        <Link
                          to={`/vehiclemaintenance/inspections/view/${inspection?.id}`}
                          className={
                            inspection?.status === 'FAIL' ? styles.failedClass : styles.passedClass
                          }
                        >
                          {truncate(inspection?.checklistName, 20)}
                        </Link>
                      </Tooltip>
                      <Tooltip title={inspection?.location}>
                        <Link
                          to="#"
                          onClick={() => handleMapModal(inspection)}
                          className={styles.locationClass}
                        >
                          {truncate(inspection?.location, 20)}
                        </Link>
                      </Tooltip>
                      <span>
                        {inspection?.inspectionDate
                          ? format(new Date(inspection.inspectionDate), 'hh:mm A')
                          : '-'}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
            {inspections.length > 4 && (
              <div>
                <Link to="#" onClick={() => handleInspectionModal(inspections)}>
                  {t('Messaging.More', { count: inspections.length - 4 })}
                </Link>
              </div>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <Table
        className={styles.tableClass}
        dataSource={sortedInspections}
        loading={isFetching}
        showSorterTooltip={false}
        columns={allColumns}
        pagination={{ defaultPageSize: 50 }}
        ref={tableRef}
        scroll={vtSettings.scroll}
        components={vtSettings.vt}
      />
      {mapModalDetails && (
        <MapModal
          show={mapModalDetails}
          coordinates={
            mapModalDetails?.coordinates && {
              lat: mapModalDetails.coordinates?.Lat,
              lng: mapModalDetails.coordinates?.Lng
            }
          }
          title={mapModalDetails?.location}
          onHide={() => setMapModalDetails(undefined)}
        />
      )}
      {inspectionModalDetails && (
        <Modal
          title={t('Inspections.AllInspections')}
          onCancel={() => setInspectionModalDetails(undefined)}
          footer={null}
          open={true}
          centered
        >
          <>
            {inspectionModalDetails?.inspections?.map(inspection => {
              return (
                <div aria-modal="true">
                  <Tooltip title={inspection?.checklistName}>
                    <FontAwesomeIcon
                      icon={inspection?.status === 'FAIL' ? faExclamationTriangle : faCheckCircle}
                      className={
                        inspection?.status === 'FAIL'
                          ? styles.failedIconClass
                          : styles.passedIconClass
                      }
                    />
                    <Link
                      to={`/vehiclemaintenance/inspections/view/${inspection?.id}`}
                      className={
                        inspection?.status === 'FAIL' ? styles.failedClass : styles.passedClass
                      }
                    >
                      {truncate(inspection?.checklistName, 20)}
                    </Link>
                  </Tooltip>
                  <Tooltip title={inspection?.location}>
                    <Link
                      to="#"
                      onClick={() => handleMapModal(inspection)}
                      className={styles.locationClass}
                    >
                      {truncate(inspection?.location, 20)}
                    </Link>
                  </Tooltip>
                  <span>
                    {inspection?.inspectionDate
                      ? format(new Date(inspection.inspectionDate), 'hh:mm A')
                      : '-'}
                  </span>
                </div>
              );
            })}
          </>
        </Modal>
      )}
    </>
  );
};

export const useTableResize = () => {
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: 1200
    }
  });

  const handleResize = entries => {
    const { width, height } = entries[0].contentRect;

    const newTableWidth = width > 10 ? width - 10 : width;
    setTableWidth(newTableWidth);

    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight - 16; // Adjust for horizontal scrollbar height
    setTableHeight(newTableHeight);

    const newVTSettings = {
      scroll: {
        y: newTableHeight,
        x: newTableWidth
      }
    };

    setVTSettings(newVTSettings);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${styles.tableClass}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return { tableRef, vtSettings };
};
